import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import phone from '../app_phone_home.png';

import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';

const MACOS: React.FC = () => {

  let history = useHistory();

  document.body.classList.remove('web-app');

  document.body.classList.add('ios-app');

  const [loading, setLoading] = useState(false);

  const purchaseStoreProductA = async () => {
    setLoading(true);
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[0].product;
      if (productToBuy == null) {
        alert('No product');
        return
      };

      try {
        const purchaseResult = await Purchases.purchaseStoreProduct({
          product: productToBuy,
          });
          if (typeof purchaseResult.customerInfo.entitlements.active['subscriber'] !== "undefined") {
            alert('Thank you for subscribing Entitlement. You will now be redirected to set your email and password.')
            history.push("/macos-signup/?duration=monthly")
          }
          // purchaseResult ? alert('Thank you for subscribing. You will now be redirected to set your email and password.') + history.push("/ios-signup/?duration=monthly") : setLoading(false);
      }
      catch (error) {
          setLoading(false);
      }

    

  }

  const purchaseStoreProductB = async () => {
    setLoading(true);
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[1].product;
      if (productToBuy == null) {
        alert('No product');
        return
      };

      try {
        const purchaseResult = await Purchases.purchaseStoreProduct({
          product: productToBuy,
          });
          if (typeof purchaseResult.customerInfo.entitlements.active['subscriber'] !== "undefined") {
            alert('Thank you for subscribing Entitlement. You will now be redirected to set your email and password.')
            history.push("/macos-signup/?duration=quarterly")
          }
          // purchaseResult ? alert('Thank you for subscribing. You will now be redirected to set your email and password.') + history.push("/ios-signup/?duration=monthly") : setLoading(false);
      }
      catch (error) {
          setLoading(false);
      }
  }

  const purchaseStoreProductC = async () => {
    setLoading(true);
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[2].product;
      if (productToBuy == null) {
        alert('No product');
        return
      };

      try {
        const purchaseResult = await Purchases.purchaseStoreProduct({
          product: productToBuy,
          });
          if (typeof purchaseResult.customerInfo.entitlements.active['subscriber'] !== "undefined") {
            alert('Thank you for subscribing Entitlement. You will now be redirected to set your email and password.')
            history.push("/macos-signup/?duration=annually")
          }
          // purchaseResult ? alert('Thank you for subscribing. You will now be redirected to set your email and password.') + history.push("/ios-signup/?duration=monthly") : setLoading(false);
      }
      catch (error) {
          setLoading(false);
      }
  }


  return (
  <>
  <div className="subscribe-wrapper" style={{top:'100px'}}>
  <div className="wrapper" style={{padding: '20px', textAlign:'center'}}>
  <h2 style={{marginBottom:'20px', display:'block'}}>Dream. Plan. Go.</h2>
  {!loading ? <>
  <Button className="subscribe-button" size="large" onClick={purchaseStoreProductA} style={{ backgroundColor: '#ef4822', color: '#fff', width: '100%', paddingTop: '10px', paddingBottom: '10px', marginBottom: '5px', maxWidth:'50%' }} variant="contained">FREE 7-DAY TRIAL<br/>MONTHLY SUBSCRIPTION $6.99</Button><br/>
  <Button className="subscribe-button" size="large" onClick={purchaseStoreProductB} style={{ backgroundColor: '#ef4822', color: '#fff', width: '100%', paddingTop: '10px', paddingBottom: '10px', marginBottom: '5px', maxWidth:'50%' }} variant="contained">FREE 7-DAY TRIAL<br/>QUARTERLY SUBSCRIPTION $14.99<br/><b style={{position:'absolute', top:'10px', right:'20px'}}>SAVE 28%</b></Button><br/>
  <Button className="subscribe-button" size="large" onClick={purchaseStoreProductC} style={{ backgroundColor: '#ef4822', color: '#fff', width: '100%', paddingTop: '10px', paddingBottom: '10px', marginBottom: '5px', maxWidth:'50%' }} variant="contained">FREE 7-DAY TRIAL<br/>ANNUAL SUBSCRIPTION $49.99<br/><b style={{position:'absolute', top:'10px', right:'20px'}}>SAVE 40%</b></Button>
  </> : <> <CircularProgress color="success" /> <h2>Please wait</h2></>}
  <br/><br/><br/>
    <div style={{ textAlign: 'center', fontSize: '1.2vw', lineHeight: '1.5' }}>
    <b>SUBSCRIBE NOW</b><br/>
    <ul style={{margin: '0 0 20px 0', padding: '0 0 0 12px', listStylePosition: 'inside'}}>
    <li>Document your dream trips and get organised so they happen!</li>
    <li>Straighten out your gear shed so everything is ready to go.</li>
    <li>Quickly make trip gear lists, meal plans, and shopping lists.</li>
    <li>Create your itinerary and budget so you have time off and financial goals to plan for.</li>
    <li>See the weight of your 4×4, camper trailer or caravan as you add and subtract items to your packing lists.</li>
    <li>See your hiking pack's base, consumables and total weight.</li>
    </ul>
    <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
    Subscriptions auto-renew until cancelled in your Apple Store settings.
    <br/><br/>
    <Button size="normal" style={{fontStyle:'normal'}} variant="contained" href="../">Go Back</Button><br/><br/>
    <a href="https://adventurecurated.com/policies/privacy-policy">Privacy Policy</a> | <a href="https://adventurecurated.com/policies/terms-of-service">Terms of Use</a><br/><a href="https://adventurecurated.com">adventurecurated.com</a>
    <br/><br/>
    </div>
    </div>
  </div></div>
  </>
  )
};

export default MACOS;