import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "../Auth";
import firebaseConfig from "../../config.js";
import { useHistory } from 'react-router-dom'
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select, { components } from 'react-select';
import firebase from "../../config"
import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import ClearIcon from "@material-ui/icons/Clear";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';

import { format } from 'date-fns'
import { confirm } from "react-confirm-box";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import Paper from '@material-ui/core/Paper';

import data_gear from '../../food_cats.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

/* 
So far we have passed the setEditing prop to index.js. 
Now we are going to pass the currentItem prop
*/
const UpdateItem = ({ setEditing, currentItem, updateItem,editing }) => {
  /*
  Sets the state of the item to the current item
  */

  const headerlogo = document.querySelector(".topnavigation img");
  
  headerlogo.classList.add('indent');
  
  const [item, setItem] = useState(currentItem);

  /*
  Side effect is that without UseEffect if you start editing one item, 
  then try to switch to another item, nothing will happen.
  The component is already open, and although the state 
  on the parent has changed, it's not registered down to the props.
  We want to let the UpdateItem form component know the props have changed.
  With the Effect Hook, we create a callback function that updates the item 
  state with the new prop thats being sent through.
  */

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setItem(currentItem);
  }, [currentItem]);

  const data = useItemsUser()
  const getdata = data?.length ? data[0] : []

  const history = useHistory()

  const onSubmit = e => {
    e.preventDefault();
    updateItem({ currentItem }, item);
  };

  const onChange = e => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const handleBrandChange = (obj) => {
    setItem({ ...item, Brand: obj.label });
  };

  const [country, setCountry] = useState({label:item.Category});
  useEffect(() => {
    setCountry({label:item.Category})
  },[item])
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);

  const handleCategoryChange = (obj) => {
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setItem({ ...item, Category: obj.label });
  };

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 25 }),
    menu: provided => ({ ...provided, zIndex: 10000000000000 }),
  }

  const handleChange = (event) => {
    setItem({ ...item, Kit: event.target.checked });
  };

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  };

  const deleteItem = async (id,options) => {
    const result = await confirm("Are you sure you want to delete this food?", optionsWithLabelChange);
    if (result) {
      firebase
        .firestore()
        .collection("food")
        .doc(id)
        .delete();
      setEditing(false);
    }
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  let intervalID;
  useEffect(() => {
    if (editing) {
      intervalID = setInterval(() => {
        const formSubmitButton = document.querySelector(".submit3sec");
        formSubmitButton.click();
      }, 1500);
    }
    return () => clearInterval(intervalID);
  }, [editing]);
  
  function handleClr(e) {
    e.stopPropagation();
    setItem({ ...item, Expiry: null });
  }

  return (
    <div>
    <div className="backchevron" onClick={() => {
            history.push('/food/index')
            setEditing(false)
          }}><FontAwesomeIcon icon={faChevronLeft} size="lg" /></div>
    <Paper className="trip-paper" elevation={3}>
      <div id="updateItem" className="updateitem">
      { /* <Button
          disableElevation
          variant="outlined"
          color="default"
          size="small"
          type="submit"
          className="btn"
          onClick={() => setEditing(false)}
          startIcon={<FirstPageIcon />}
        >
          Back
  </Button> */ }
        <TextField required fullWidth id="standard-basic" label="Food Name" value={item.Name} name="Name" onChange={onChange} type="text" variant="outlined" />
        { /* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Expiry Date"}
                  inputFormat="dd/MM/yyyy"
                  closeOnSelect={true}
                  value={item.Expiry ?? null}
                  views={['day']}
                  onChange={(newValue) => {
                    setItem({ ...item, Expiry: format(newValue,"MM/dd/yyyy") });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={(e) => handleClr(e)}>
                        <ClearIcon />
                      </IconButton>
                    )
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} fullWidth />}
                />
                </LocalizationProvider> */ }
        
        
        <Select
          menuPortalTarget={document.body}
          isSearchable={ false }
          fullWidth
          placeholder={"Category"}
          options={data_gear}
          onChange={handleCategoryChange}
          name="Category"
          value={country}
          getOptionLabel={x => x.label}
          getOptionValue={x => x.label}
          className="select indent"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          components={{
            ValueContainer: CustomValueContainer
          }}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: "#fff",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 12
            })
          }}
        />
        <div style={{ display: 'block', alignItems: 'center', position: 'relative' }}>
        <NumberFormat style={{width:'100%'}} allowNegative={false} inputProps={{ inputMode: 'decimal',}} InputProps={{ endAdornment: <InputAdornment position="end">{getdata?.WeightPref == true || getdata?.WeightPref == undefined ? 'kg' : 'lb'}</InputAdornment>, }} label="Weight" customInput={TextField} variant="outlined" name="Weight" value={item.Weight} thousandSeparator={true} fixedDecimalScale="." decimalScale={3} onChange={onChange} />
        <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-absolute' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="For accurate results use quality kitchen scales." placement="top-start" arrow>
                  <IconButton className="tooltip tooltiprelocate" size="small" aria-label="edit" style={{ position: 'absolute', top: '-2px', right: '42px' }}><NotListedLocationIcon /></IconButton>
                </Tooltip>
              </div>
        </div><br/>
        <Button
          style={{display:'none'}}
          disableElevation
          variant="outlined"
          color="default"
          size="large"
          type="submit"
          className="submit3sec"
          onClick={onSubmit}
          startIcon={<SaveAltTwoToneIcon />}
        >
          Update Food
      </Button>
      <Button
          disableElevation
          style={{opacity:'0', pointerEvents:'none'}}
          variant="outlined"
          color="default"
          size="large"
          type="submit"
          className="btn greybtn"
          onClick={() => {
            history.push('/food/index')
            setEditing(false)
            // window.scrollTo(0, 0);
            var delay=0;//1*6 seconds
            setTimeout(function(){
              location.hash = item.id; //scrolls to element with given id
              document.getElementById(item.id).classList.add("just-edited");
            },delay); 
          }
          }
          startIcon={<FirstPageIcon />}
        >
          Back
      </Button>&nbsp;&nbsp;

      <Button
          disableElevation
          variant="outlined"
          color="secondary"
          size="large"
          type="submit"
          className="btn deletebtn redbtn"
          onClick={() => deleteItem(item.id)}
          startIcon={<DeleteTwoToneIcon />}
        >
          Delete
      </Button>
      </div>
    </Paper>
    </div>
  );
};
export default UpdateItem;