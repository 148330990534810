import React from "react";
import { BulletedTextArea } from "react-bulleted-textarea";

const bulletChar = '•'

const TextArea = ({setKitDetails,kitdetails}) => {
    return (
      <div>
        <BulletedTextArea
            onChange={(value) => setKitDetails(value)}
            values={kitdetails}
            bulletChar={bulletChar}
         />
      </div>
     );
};

export default TextArea;