import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "../Auth";
import firebaseConfig from "../../config.js";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select from 'react-select';
import firebase from "../../config"

import { DataGrid } from '@material-ui/data-grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { useHistory } from 'react-router-dom'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { isPlatform } from "@ionic/react";
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { File } from '@awesome-cordova-plugins/file';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faCompass } from '@fortawesome/pro-solid-svg-icons'

const todaysdate = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate().toString().padStart(2, "0");

// console.log(new Date().toLocaleString("en-US", { year: 'numeric', month : '2-digit', day : '2-digit'}));
const useItemsTrips = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("trips") //access "items" collection
      .where("User", "==", user1)
      .orderBy("DateFrom", "asc")
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsTrips = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsTrips); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const useItemsTripsC = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("trips") //access "items" collection
      .where("User", "==", user1)
      .orderBy("DateTo", "desc")
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsTrips = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsTrips); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const deleteItem = id => {
  if (window.confirm('Are you sure to delete this record?')) {
    firebase
      .firestore()
      .collection("trips")
      .doc(id)
      .delete();
  }
};
const ItemListTrips = ({ editItem, addItem, editing }) => {
  const listItemTrips = useItemsTrips();
  const listItemTripsC = useItemsTripsC();
  const [futureData, setFutureData] = useState([])
  const [nodates, setnodates] = useState([])
  const [pastData, setPastData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {

    const filterfuture = listItemTrips.filter(el => {
      var GivenDate = new Date(el.DateTo.toDate());
      var CurrentDate = new Date();
      CurrentDate.setDate(CurrentDate.getDate() - 1);
      GivenDate = new Date(GivenDate);
      if (GivenDate >= CurrentDate) {
        return true
      } else {
        return false
      }
    })
    setFutureData(filterfuture)

    const filterfuturenodates = listItemTrips.filter(el => {
      if (el.DateTo == '') {
        return true
      } else {
        return false
      }
    })
    setnodates(filterfuturenodates)

    const filterpast = listItemTripsC.filter(el => {
      var GivenDate = new Date(el.DateTo.toDate());
      var CurrentDate = new Date();
      CurrentDate.setDate(CurrentDate.getDate() - 1);
      GivenDate = new Date(GivenDate);
      if (GivenDate <= CurrentDate) {
        return true
      } else {
        return false
      }
    })
    setPastData(filterpast)

  }, [listItemTripsC])

  function descendingComparator(a, b, orderBy) {
    // console.log('a',a)
    // console.log('b',b)
    // console.log('orderBy',orderBy)
    if (b[orderBy] < a[orderBy]) {
      // console.log('-1')
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      // console.log('1')
      return 1;
    }
    // console.log('0')
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index])
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    return stabilizedRowArray.map((el) => el[0])
  }


  const [orderDirection, setorderDirection] = useState("asc")
  const [valueToOrderBy, setvalueToOrderBy] = useState("")

  const createSortHandler = (property) => (event) => {
    setvalueToOrderBy(property);
    if (orderDirection == "desc") {
      setorderDirection("asc");
    } else {
      setorderDirection("desc");
    }
  };

  const [selectedIndex, setSelectedIndex] = React.useState("")

  const handleCollapse = index => {
    if (selectedIndex === index) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(index)
    }
  }

  const [dp, setDp] = useState(true);


  const getid = useHistory()

  useEffect(() => {
    const ia = getid?.location?.search
    const awa = ia?.split('?')
    if(awa?.length > 1){
      if(awa[1] == "bucket"){
        setDp(false)
      }
    }else{
      setDp(true)
    }
  }, [getid])

  const DateFormaterFunction = (date) => {
    if (date == '') {
      return <span>No Date</span>
    } else {
      const newDate = new Date(date).toLocaleDateString()
      const splitDate = newDate?.split('/')
      return <span>{splitDate[0]}/{splitDate[1]}/{splitDate[2]}</span>
    }
  }

  const savePDF = () => {
    // See https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
    
    var randomno = Math.random()
    
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "letter"
    });
    var img = new Image()
    img.src = '/logo_black.png'
    // doc.addImage(img, 'PNG', 40, 10, 183, 20)
    autoTable(doc, {
      html: '.table',
      includeHiddenHtml: true,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    doc.setFontSize(7)
    doc.text('ADVENTURE CURATED TRIP PLANNER',40,15)
    // doc.setFont(undefined, 'regular')
    // doc.text(('MEALS - ' + name), 250, 19)
    doc.output('bloburl');
    let pdfOutput = doc.output();
    if (isPlatform("ios")) {
      // for iOS device
      const directory = File.tempDirectory;
      const fileName = `Trip_Planner`+randomno+`.pdf`
      File.writeFile(directory, fileName, pdfOutput, true).then(success => {
        FileOpener.showOpenWithDialog(directory + fileName, 'application/pdf')
          .then(() => console.log('File opened'))
          .catch(e => console.log('Error opening file', e));
      },
        err => {
          console.log(" writing File error : ", err)
        })
    } else {
      // for desktop
      doc.save('Trip_Planner'+randomno+'.pdf');
    }
    
  };

  return (
    <React.Fragment>
      {addItem == true || editing == true ? null : (
      <>
      <div className="toggle4trip" style={{display: 'block', textAlign: 'center'}}>
      <Button className={(dp == false) ? "btntoggle" : "btntoggle btntoggled"} onClick={() => setDp(true)} variant="outlined" size="large"><FontAwesomeIcon icon={faCompass} size="lg" /> ALL TRIPS</Button> <Button className={(dp == true) ? "btntoggle" : "btntoggle btntoggled"} onClick={() => setDp(false)} variant="outlined" size="large"><FontAwesomeIcon icon={faStar} size="lg" /> BUCKET LIST</Button>
      </div>
      { /* <Button variant="outlined" onClick={() => {
        setDp(!dp);
      }}>{dp ? 'BUCKET LIST' : 'ALL TRIPS'}</Button> */ }
      { /* <Button className="pdfbtn" variant="outlined" onClick={savePDF} style={{float: 'right'}}>Save current view as PDF</Button> */ }
      { /* <h3 className="h3gradient">FUTURE</h3> */ }
      <TableContainer component={Paper} elevation={3}>
        <div className="table-responsive-vertical">
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="trip-width"><TableSortLabel
                  active={valueToOrderBy === "Name"}
                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                  onClick={createSortHandler("Name")}
                >
                  TRIP NAME
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "DateFrom"}
                  direction={valueToOrderBy === "DateFrom" ? orderDirection : 'asc'}
                  onClick={createSortHandler("DateFrom")}
                >
                  START DATE
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "DateTo"}
                  direction={valueToOrderBy === "DateTo" ? orderDirection : 'asc'}
                  onClick={createSortHandler("DateTo")}
                >
                  FINISH DATE
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "diffInDays"}
                  direction={valueToOrderBy === "diffInDays" ? orderDirection : 'asc'}
                  onClick={createSortHandler("diffInDays")}
                >
                  NO. OF DAYS
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "Category"}
                  direction={valueToOrderBy === "Category" ? orderDirection : 'asc'}
                  onClick={createSortHandler("Category")}
                >
                  MODE OF TRAVEL
             </TableSortLabel></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sortedRowInformation(futureData, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    const diffInMs = new Date(item.DateTo.toDate()) - new Date(item.DateFrom.toDate())
                    const diffInDays = (diffInMs / (1000 * 60 * 60 * 24) + 1).toFixed();
                    return (
                      <React.Fragment>
                        {item.Bucket == true && (
                          <TableRow hover key={index + item.id} id={item.id} onClick={() => editItem(item)}>
                            <TableCell data-title="Name Alt">
                            {item.Bucket == true && (
                              <StarIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                            {item.Bucket == false && (
                              <StarBorderIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                              &nbsp;&nbsp;{item.Name}</TableCell>
                            <TableCell data-title="Start Date" className="topleft">{new Date(item.DateFrom.toDate()).toLocaleDateString()== '31/12/2099' ? '-' : new Date(item.DateFrom.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="Finish Date" className="topright hidecell">{new Date(item.DateTo.toDate()).toLocaleDateString()== '31/12/2099' ? '-' : new Date(item.DateTo.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="noofdayz" className="topright hidecell">{item.noofdays > 0 ? <>{item.noofdays}</> : <>{diffInDays == 1 ? '-' : Math.ceil(diffInDays)}</>}</TableCell>
                            <TableCell data-title="Category" className="hidecell">{item.Category == "" ? '-' : item.Category}</TableCell>
                          </TableRow>
                        )}
                        {item.Bucket == false && (
                          dp && (
                            <TableRow hover key={index + item.id} id={item.id} onClick={() => editItem(item)}>
                            <TableCell data-title="Name Alt">
                            {item.Bucket == true && (
                              <StarIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                            {item.Bucket == false && (
                              <StarBorderIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                              &nbsp;&nbsp;{item.Name}</TableCell>
                            <TableCell data-title="Start Date" className="topleft">{new Date(item.DateFrom.toDate()).toLocaleDateString()== '31/12/2099' ? '-' : new Date(item.DateFrom.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="Finish Date" className="topright hidecell">{new Date(item.DateTo.toDate()).toLocaleDateString()== '31/12/2099' ? '-' : new Date(item.DateTo.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="noofdayz" className="topright hidecell">{item.noofdays > 0 ? <>{item.noofdays}</> : <>{diffInDays == 1 ? '-' : Math.ceil(diffInDays)}</>}</TableCell>
                            <TableCell data-title="Category" className="hidecell">{item.Category == "" ? '-' : item.Category}</TableCell>
                          </TableRow>
                          ))}
                      </React.Fragment>
                    )
                  })}
              

            </TableBody>
          </Table>
        </div>
      </TableContainer>

      <h3>COMPLETE</h3>
      <TableContainer component={Paper} elevation={3}>
        <div className="table-responsive-vertical">
          <Table className="table" size="small">
          <TableHead>
              <TableRow>
                <TableCell className="trip-width"><TableSortLabel
                  active={valueToOrderBy === "Name"}
                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                  onClick={createSortHandler("Name")}
                >
                  TRIP NAME
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "DateFrom"}
                  direction={valueToOrderBy === "DateFrom" ? orderDirection : 'asc'}
                  onClick={createSortHandler("DateFrom")}
                >
                  START DATE
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "DateTo"}
                  direction={valueToOrderBy === "DateTo" ? orderDirection : 'asc'}
                  onClick={createSortHandler("DateTo")}
                >
                  FINISH DATE
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "diffInDays"}
                  direction={valueToOrderBy === "diffInDays" ? orderDirection : 'asc'}
                  onClick={createSortHandler("diffInDays")}
                >
                  NO. OF DAYS
             </TableSortLabel></TableCell>
                <TableCell><TableSortLabel
                  active={valueToOrderBy === "Category"}
                  direction={valueToOrderBy === "Category" ? orderDirection : 'asc'}
                  onClick={createSortHandler("Category")}
                >
                  MODE OF TRAVEL
             </TableSortLabel></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sortedRowInformation(pastData, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    const diffInMs = new Date(item.DateTo.toDate()) - new Date(item.DateFrom.toDate())
                    const diffInDays = (diffInMs / (1000 * 60 * 60 * 24) + 1);
                    return (
                      <React.Fragment>
                        {item.Bucket == true && (
                          <TableRow hover key={index + item.id} id={item.id} onClick={() => editItem(item)}>
                            <TableCell data-title="Name Alt">
                            {item.Bucket == true && (
                              <StarIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                            {item.Bucket == false && (
                              <StarBorderIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                                  &nbsp;&nbsp;{item.Name}</TableCell>
                            <TableCell data-title="Start Date" className="topleft">{new Date(item.DateFrom.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="Finish Date" className="topright hidecell">{new Date(item.DateTo.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="noofdayz" className="topright hidecell">{item.noofdays > 0 ? <>{item.noofdays}</> : <>{diffInDays == 1 ? '-' : Math.ceil(diffInDays)}</>}</TableCell>
                            <TableCell data-title="Category" className="hidecell">{item.Category == "" ? '-' : item.Category}</TableCell>
                          </TableRow>
                        )}
                        {item.Bucket == false && (
                          dp && (
                            <TableRow hover key={index + item.id} id={item.id} onClick={() => editItem(item)}>
                            <TableCell data-title="Name Alt">
                            {item.Bucket == true && (
                              <StarIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                            {item.Bucket == false && (
                              <StarBorderIcon fontSize="small" style={{ color: 'gold' }} />
                            )}
                                  &nbsp;&nbsp;{item.Name}</TableCell>
                            <TableCell data-title="Start Date" className="topleft">{new Date(item.DateFrom.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="Finish Date" className="topright hidecell">{new Date(item.DateTo.toDate()).toLocaleDateString()}</TableCell>
                            <TableCell data-title="noofdayz" className="topright hidecell">{item.noofdays > 0 ? <>{item.noofdays}</> : <>{diffInDays == 1 ? '-' : Math.ceil(diffInDays)}</>}</TableCell>
                            <TableCell data-title="Category" className="hidecell">{item.Category == "" ? '-' : item.Category}</TableCell>
                          </TableRow>
                            ))}
                      </React.Fragment>
                    )
                  }
                  )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      </>
      )}
    </React.Fragment>
  );
};

export default ItemListTrips;