import React from "react";
import Slider from "react-slick";
import Button from '@material-ui/core/Button';
const SimpleSlider = ({ articles }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {articles.map((item,i) => (
                    <div className="text-over-image" key={i}>
                        <img className="dashimagec" src={item.image}></img>
                        <div className="caption" style={{lineHeight : '1.3'}}>
                            { /* <a
                                className="link"
                                href={item.url}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                title={item.title}
                                aria-label={item.url}
                                key={item.url}
                            >
                                
                            </a> */ }
                            { /* <Button variant="outlined" size="medium" href={item.url} target="_blank">Read More</Button> */ }
                            <h3 style={{display: 'block'}}>{item.title}</h3>
                            {item.content_text.replace("[&#8230;]", "...").replace("&#8217;", "'").replace("&#160;", "")}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default SimpleSlider