import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "../Auth";
import firebaseConfig from "../../config.js";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select from 'react-select';
import firebase from "../../config"

import { useHistory } from 'react-router-dom'

import { DataGrid } from '@material-ui/data-grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
// import SearchBar from "material-ui-search-bar";
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { el } from "date-fns/locale";

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const useItemsFood = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("food") //access "items" collection
      .where("User", "==", user1)
      .orderBy("Category", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsFood = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsFood); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const deleteItem = id => {
  if (window.confirm('Are you sure to delete this record?')) {
    firebase
      .firestore()
      .collection("food")
      .doc(id)
      .delete();
  }
};

function useStateWithDep(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return [value, setValue];
}


const ItemListFood = ({ editItem, addItem, editing }) => {
  const listItemFood = useItemsFood();
  const [rows, setRows] = useStateWithDep(listItemFood);
  const [searched, setSearched] = useState("");
  const requestSearch = (searchedVal) => {
    const filteredRows = listItemFood.filter((item) => {
      return item.Name.toUpperCase().includes(searchedVal.toUpperCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  function descendingComparator(a, b, orderBy) {
    // console.log('a',a)
    // console.log('b',b)
    // console.log('orderBy',orderBy)
    if (b[orderBy] < a[orderBy]) {
      // console.log('-1')
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      // console.log('1')
      return 1;
    }
    // console.log('0')
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index])
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    return stabilizedRowArray.map((el) => el[0])
  }


  const [orderDirection, setorderDirection] = useState("asc")
  const [valueToOrderBy, setvalueToOrderBy] = useState("Name")

  rows?.sort(function (a, b) {
    if (a.Category < b.Category) { return -1; }
    if (a.Category > b.Category) { return 1; }
    return 0;
  })

  const categorizedData = rows.reduce((acc, curr) => {
    const { id, Category, Weight, Name, User } = curr;
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ id, Category, Weight, Name, User });
    return acc;
  }, {});

  const newData = Object.keys(categorizedData)?.filter(key => key)

  const getbreakfast = rows.filter(el => el.Category == "BREAKFAST" ? true : false)
  const getlinch = rows.filter(el => el.Category == "LUNCH" ? true : false)
  const getDinner = rows.filter(el => el.Category == "DINNER" ? true : false)
  const getSnack = rows.filter(el => el.Category == "SNACKS" ? true : false)
  const getpantry = rows.filter(el => el.Category == "PANTRY" ? true : false)
  const getDrinks = rows.filter(el => el.Category == "COOL DRINKS" ? true : false)
  const gethot = rows.filter(el => el.Category == "HOT DRINKS" ? true : false)
  const getalcholo = rows.filter(el => el.Category == "ALCOHOL" ? true : false)
  const getpet = rows.filter(el => el.Category == "PET" ? true : false)
  const getbaby = rows.filter(el => el.Category == "BABY" ? true : false)
  const getblank = rows.filter(el => el.Category == "" | el.Category == null ? true : false)

  const createSortHandler = (property) => (event) => {
    setvalueToOrderBy(property);
    if (orderDirection == "desc") {
      setorderDirection("asc");
    } else {
      setorderDirection("desc");
    }
  };

  function isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

  const naa = useItemsUser()
  const newata = naa[0]
  var preferenceforweight = newata?.WeightPref == true || newata?.WeightPref == undefined ? 'kg' : 'lb'

  const toHash = (category) => {
    return category
    .replace(/[,.\- ]/g, '-').toLowerCase();   
  };


  const keys = newData.map(toHash);

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (keys.includes(hash)) {
      setExpanded((prev) => ({
        ...prev,
        [hash]: true
      }));
    }
  }, []);

  const [expanded, setExpanded] = useState(
    keys.reduce((obj, key) => {
      obj[key] = false;
      return obj;
    }, {})
  );

  const toggleExpanded = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <>
    {addItem == true || editing == true ? null : (
      <>
      {getblank?.length ? <>
      <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0', color:'red'}}>{getblank?.length ? "UNASSIGNED" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getblank?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getblank, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : item.Weight + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

      {getbreakfast?.length ? <>
      <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                          {getbreakfast.id}
                        <h3 style={{margin:'0'}}>{getbreakfast?.length ? "BREAKFAST": null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getbreakfast?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getbreakfast, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getlinch?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getlinch?.length ? "LUNCH" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getlinch?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getlinch, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getDinner?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getDinner?.length ? "DINNER" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getDinner?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getDinner, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getSnack?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getSnack?.length ? "SNACKS" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getSnack?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getSnack, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getpantry?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getpantry?.length ? "PANTRY" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getpantry?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getpantry, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getDrinks?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getDrinks?.length ? "COOL DRINKS" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getDrinks?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getDrinks, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {gethot?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{gethot?.length ? "HOT DRINKS" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {gethot?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(gethot, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getalcholo?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getalcholo?.length ? "ALCOHOL" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getalcholo?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getalcholo, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getbaby?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getbaby?.length ? "BABY" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getbaby?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getbaby, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }

                    {getpet?.length ? <>
                    <Accordion elevation={1} defaultExpanded={false}
                      className="filter-items-accordion"
                      onChange={() => toggleExpanded(key)}
                      style={{margin: '6px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                        <h3 style={{margin:'0'}}>{getpet?.length ? "PET" : null}</h3>


                    </Typography></AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
        {getpet?.length ? (
          <div className="table-responsive-vertical">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Name"}
                      direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Name")}
                    >
                      NAME
                          </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Weight"}
                      direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Weight")}
                    >
                      WEIGHT
                          </TableSortLabel>
                  </TableCell>
                  { /* <TableCell>
                    <TableSortLabel
                      active={valueToOrderBy === "Expiry"}
                      direction={valueToOrderBy === "Expiry" ? orderDirection : 'asc'}
                      onClick={createSortHandler("Expiry")}
                    >
                      EXPIRY
                          </TableSortLabel>
                  </TableCell> */ }
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getbreakfast.sort((a, b) => a.Name.localeCompare(b.Name)).map((item, index) => {
                  return (
                    <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                      <TableCell data-title="Name">{item.Name}</TableCell>
                      <TableCell data-title="Weight" className="topright">{item.Weight}</TableCell>
                    </TableRow>
                  )
                }
                )} */}
                {sortedRowInformation(getpet, getComparator(orderDirection, valueToOrderBy))
                  .map((item, index) => {
                    var aaa = item.Expiry;
                    if (aaa) {
                    const [month, day, year] = aaa.split('/');
                    var expirydate = new Date(+year, month - 1, +day);
                    var formatexpiry = expirydate.toLocaleDateString('en-GB');
                    } else {
                      var formatexpiry = '-';
                      var expirydate = new Date();
                    }
                    return (
                      <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                        <TableCell data-title="Name">{item.Name}</TableCell>
                        <TableCell data-title="Weight" className="topright">{item.Weight == 0 || item.Weight == null ? '-' : Number(item.Weight).toFixed(3) + preferenceforweight}</TableCell>
                        { /* <TableCell data-title="Expiry" className={(new Date() > expirydate) ? "negative" : "positive"}><span className={(formatexpiry == '-') ? "notvalid" : "valid"}>{formatexpiry}</span></TableCell> */ }
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </TableContainer>
                    </AccordionDetails>
                    </Accordion></> : null }      
      </>
    )}
    <br/>
    { /* <Button className="buttonwithsearch" variant="outlined" size="large" href="https://adventurecurated.com/camp/food/" target="_blank">View Shop</Button> */ }
    <div style={{clear:'both'}}></div>
    <br/>
    </>
  );
};

export default ItemListFood;