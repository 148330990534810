import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "../config"
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


const useItemsUser = () => {
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    useEffect(() => {
        const unsubscribe = firebase
            .firestore() //access firestore
            .collection("user") //access "items" collection
            .where("id", "==", `${user1}`)
            .onSnapshot(snapshot => {
                //You can "listen" to a document with the onSnapshot() method.
                const listItemsUsers = snapshot.docs.map(doc => ({
                    //map each document into snapshot
                    id: doc.id, //id and data pushed into items array
                    ...doc.data() //spread operator merges data to id.
                }));
                setItems(listItemsUsers); //items is equal to listItems
            });
        return () => unsubscribe();
    }, []);
    return items;
};

export default function FormDialog() {
    const data = useItemsUser()
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [customgear, setcustomgear] = useState([{
        label: '',
        value: '',
        id: uuidv4(),
    }
    ])

    const onChangeHandler = (e, i, index) => {
        const values = [...customgear];
        if (e.target.name === "Brand") {
            values[i].label = e.target.value.toUpperCase();
            values[i].value = e.target.value.toUpperCase();
        }
        setcustomgear(values)
    }
    const handleAddFields = () => {
        const values = [...customgear];
        values.push({
            label: '',
            value: '',
            id: uuidv4(),
        });
        setcustomgear(values);
    };
    const handleRemoveFields = (index) => {
        const newData = customgear.filter((el, i) => i != index)
        setcustomgear(newData)
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        if (data[0].Brand.length) {
            customgear.map(el => {
                data[0].Brand.push(el)
            })
        }
        firebase
            .firestore()
            .collection("user")
            .doc(user1)
            .update({
                Brand: data[0]?.Brand
            }).then(() => {
                setOpen(false);
                setcustomgear([{
                    label: '',
                    value: '',
                    id: uuidv4(),
                }
                ])
            }
            )
    };


    const length = customgear?.length - 1;
    return (
        <div>
            <Button variant="outlined" className="profile-add" startIcon={<AddCircleIcon />} size="large" aria-label="edit" style={{ color: '#202020', borderColor: '#202020' }} onClick={() => handleClickOpen()}>Add Brand</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Brand</DialogTitle>
                <DialogContent>
                    {customgear?.length && customgear?.map((el, i) => {
                        return (
                            <>
                                <TextField
                                    InputLabelProps={{ shrink: true, }}
                                    id="standard-basic"
                                    label="Brand"
                                    value={el.label}
                                    required={true}
                                    name="Brand"
                                    onChange={(e) => onChangeHandler(e, i)}
                                    type="text"
                                    variant="outlined"
                                    fullwidth
                                />
                                { /* 
                                {length == i && (
                                    <IconButton size="small" aria-label="edit" onClick={() => handleAddFields()}><AddCircleIcon /></IconButton>
                                )}
                                <IconButton size="small" aria-label="edit" onClick={() => handleRemoveFields(i)}><RemoveCircleIcon /></IconButton>
                                */ }
                            </>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose2}>Add Brand</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}