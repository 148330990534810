import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import HomeLogin from "./components/LoginDashboard";
import Dashboard from "./components/Dashboard";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import SignUpI from "./components/SignUpInternal";
// import SignUpBeta from "./components/SignUpBeta";
import SignUpTrial from "./components/SignUpTrial";
import Redeem from "./components/Redeem";
import SignOut from "./components/SignOut";
import Support from "./components/Support";
import Gear from "./components/Gear";
import IOS from "./components/IOS";
import IOSignup from "./components/ios-signup";
import ANDROID from "./components/ANDROID";
import AndroidSignup from "./components/android-signup";
import MACOS from "./components/MACOS";
import MACOSSignup from "./components/macos-signup";
import MyIndex from "./components/gear/MyIndex";
import MyIndexFood from "./components/food/MyIndex";
// import MyIndexMeals from "./components/meals/MyIndex";
import MyIndexTrips from "./components/trips/MyIndex";
import MyIndexUser from "./components/user/MyIndex";
import Garage from "./components/garage/MyIndex";
// import BackofHouse from "./components/BackofHouse";
import TopNav from "./components/navigation/header";
import BottomNav from "./components/navigation/footer";
import { AuthProvider } from "./components/Auth";
import './App.css';


const App = () => {
  return (
    <AuthProvider>    
      <Router>
      <TopNav /> 
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/welcome" component={HomeLogin} />
          { /* <Route exact path="/dashboard" component={Dashboard} /> */ }
          <Route exact path="/gear" component={Gear} />
          <Route exact path="/login" component={LogIn} />
          <Route exact path="/subscribe" component={SignUp} />
          <Route exact path="/acpromosignup" component={SignUpI} />
          { /* <Route exact path="/subscribe-beta" component={SignUpBeta} /> */ }
          <Route exact path="/subscribe-trial" component={SignUpTrial} />
          <Route exact path="/redeem" component={Redeem} />
          <Route exact path="/signout" component={SignOut} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/gear/index" component={MyIndex} />
          <Route exact path="/food/index" component={MyIndexFood} />
          { /* <Route exact path="/meals/index" component={MyIndexMeals} /> */ }
          <Route exact path="/trips/index" component={MyIndexTrips} />
          <Route exact path="/user/index" component={MyIndexUser} />
          <Route exact path="/garage/index" component={Garage} />
          { /* <Route exact path="/backofhouse" component={BackofHouse} /> */ }
          <Route exact path="/ios" component={IOS} />
          <Route exact path="/android" component={ANDROID} />
          <Route exact path="/macos" component={MACOS} />
          <Route exact path="/ios-signup" component={IOSignup} />
          <Route exact path="/android-signup" component={AndroidSignup} />
          <Route exact path="/macos-signup" component={MACOSSignup} />
        </Switch>
        <BottomNav /> 
      </Router>
    </AuthProvider>
  );
};

export default App;