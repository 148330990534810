import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./Auth";
import firebaseConfig from "../config.js";
import GearJson from '../gear_cats_update.json'
import BrandJson from '../brand_cats.json'
import StorageLocation from '../locations_cats.json'
import FoodSample from '../food_sample.json'
import GearSample from '../gear_sample.json'
import TripsSample from '../trips_sample.json'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardInput from './carinput'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';

const SignUp = () => {

  // firebaseConfig.auth().signOut();

  const [currentUser, setCurrentUser] = useState(null);
  const [planvalue, setplanvalue] = useState('')
  const [togle, setTogle] = useState(false)
  const [data, setdat] = useState([])

  const checkuserexist = (email) => {
    const userfiler = data.filter(el => el.email == email.value ? true : false)
    let jauwa;
    if (userfiler.length) {
      jauwa = true
    } else {
      jauwa = false
    }
    return jauwa
  }

  const [errormsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (!currentUser) {
    document.body.style.padding = '0';
    document.body.style.background = '#202020';
    } else {
      null
    }
}, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    try {
      setTogle(true)
      if (email.value == '' && password.value == '') {
        setTogle(false)
        return;
      }
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email.value)) {
        alert("Email format is incorrect")
        setTogle(false)
        return;
      }
      if (password.value.length < 8) {
        alert("Password must be greater then 8 characters")
        setTogle(false)
        return
      }
      const heya = checkuserexist(email)
      if (heya == true) {
        alert("A user with this email already exists")
        setTogle(false)
        return;
      }

      let duration = (new URLSearchParams(window.location.search)).get("duration")

      firebaseConfig.auth().createUserWithEmailAndPassword(email.value, password.value)
      .then((userCredential) => {
        // Step 2: Add additional data to collection
        const user = userCredential.user;
        const uid = user.uid;
        
        const foodarray = FoodSample.map((array) => ({
          ...array,
          User: uid,
        }));
                        
        foodarray.forEach((doc) => {
          firebaseConfig.firestore().collection("food").doc().set(doc);
        });

        const geararray = GearSample.map((array) => ({
          ...array,
          User: uid,
        }));
                        
        geararray.forEach((doc) => {
          firebaseConfig.firestore().collection("gear").doc().set(doc);
        });
        
        const tripsarray = TripsSample.map((array) => ({
          ...array,
          User: uid,
          DateFrom: new Date(Date.now() + ( 3600 * 1000 * 120)),
          DateTo: new Date(Date.now() + ( 3600 * 1000 * 240))
        }));
                        
        tripsarray.forEach((doc) => {
          firebaseConfig.firestore().collection("trips").doc().set(doc);
        });

        const additionalData = {
                  Name: yourname.value,
                  storageLocation: StorageLocation,
                  Brand: BrandJson,
                  planid: 'APPLE',
                  Travellers: [{Name:'', Weight:''}],
                  FourbyFourPlusCaravan: {FourbyFour_Empty: 0, FourbyFour_Payload: 0, FourbyFour_Water: 0, FourbyFour_GVM: 0, FourbyFour_FUEL: 0, FourbyFour_TOTAL: 0, id: Math.random() * 6 * 10},
                  AdventureMotorBike: {AdventureMotorBike_Empty: 0, AdventureMotorBike_GVM: 0, AdventureMotorBike_Payload: 0, AdventureMotorBike_Fuel: 0, AdventureMotorBike_Water: 0, AdventureMotorBike_Total: 0, id: Math.random() * 6 * 10},
                  BoatWeight: {BoatWeight_Empty: 0, BoatWeight_ATM: 0, BoatWeight_Payload: 0, BoatWeight_Water: 0, BoatTotal: 0, BoatforbyforTotal: 0, BoatWeight_Fuel: 0, BoatWeight_forbyfor_BoatBall: 0, BoatWeight_GCM: 0, id: Math.random() * 6 * 10},
                  Caravan: {Caravan_Empty: 0, Caravan_ATM: 0, Caravan_Payload: 0, Caravan_Water: 0, Caravan_Greywater: 0, Caravan_Total: 0, Caravan_forbyfor_Total: 0, caravan_forbyfor_caravanBall: 0, caravan_GCM: 0, id: Math.random() * 6 * 10},
                  Camper: {Camper_Empty: 0, Camper_ATM: 0, Camper_Payload: 0, Camper_Water: 0, Camper_Total: 0, Camper_CamperBall: 0, Camper_forbyfor_Total: 0, CamperplusforbyforTotal: 0, camperforbyfor_GCM: 0, id: Math.random() * 6 * 10},
                  TorquePower: {PowerHP: 0, PowerKW: 0, Torque: 0},
                  Subcription_Status: true,
                  plancreated: new Date(),
                  planexpiry: duration == 'monthly' ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                  GearCategories: GearJson
        };

        // Example additional data
        const userData = {
          id: uid,
          email: email.value,
          ...additionalData
        };
        firebaseConfig
        .firestore().collection("user").doc(uid).set(userData)
        .then(() => {
          console.log("User created and additional data added successfully");
          Purchases.setEmail({ email: email.value });
          window.location.href="/trips/index";
        })
        .catch((error) => {
          console.error("Error adding additional data: ", error);
        });
    })
    .catch((error) => {
      console.error("Error creating user: ", error);
    });
    } catch (error) {
      alert("Subscription failed. Please try again.");
      setTogle(false)
    } 
    return setTogle(false)
  };

  return (
    <>
      <div className="wrapper front-wrapper">
        <form onSubmit={handleSubmit} className="login-form">
          <h2 style={{fontSize:'18px'}}>Complete your sign-up by creating your login</h2><br />
          <TextField InputLabelProps={{ required: false }} style={{margin:0}} required fullWidth id="yourname" label="Name" variant="outlined" type="text" name="yourname" />
          <TextField InputLabelProps={{ required: false }} style={{margin:0}} required fullWidth id="email" label="Email" variant="outlined" type="email" name="email" />
          <TextField InputLabelProps={{ required: false }} style={{margin:0}} required fullWidth id="password" variant="outlined" label="Password" type="password" name="password" />
          <Button
            disableElevation
            disabled={togle}
            variant="outlined"
            color="default"
            size="large"
            type="submit"
            className="btn"
            style={{backgroundColor: '#fff', color:'#202020', paddingLeft:'20px', paddingTop:'15px', paddingBottom:'15px', marginBottom:'10px', width:'400px', border:'0'}}
          >
            Sign Up
            </Button>
            {errormsg ? 
            <div style={{display:'block',fontSize:'12px',paddingTop:'10px'}}>Error {errormsg}</div> : null }
        </form>
      </div>
    </>
  );
};

export default SignUp;