import React, { useContext,useState,useEffect } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

import firebase from "../config"

const SignOut = () => {
  const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
      return <Redirect to="/" />;
    }
    firebase.auth().signOut().then(() => {
      console.log('Signed Out');
    }).catch((error) => {
      // An error happened.
    });
    
  return (
    <>
    <div className="wrapper">
    Signing Out!
    </div>
    </>
  );
};
export default SignOut;