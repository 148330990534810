import React, { useMemo } from 'react'
import TextField from '@material-ui/core/TextField';

const Weights = ({ data, country, listItemUser, gear }) => {
    const newarr = [];
    data?.map(el => {
        el?.gearHiking?.map(els => {
            newarr?.push(els)
        })
    })
    const newarr2 = [];
    data?.map(el => {
        el?.foodHiking?.map(els => {
            newarr2?.push(els)
        })
    })
    const newarr3 = [];
    data?.map(el => {
        el?.mealHiking?.map(els => {
            newarr3?.push(els)
        })
    })
    const user1 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[0]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[0]?.Name == el.Name ? true : false);
    const user2 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[1]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[1]?.Name == el.Name ? true : false);
    const user3 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[2]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[2]?.Name == el.Name ? true : false);
    const user4 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[3]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[3]?.Name == el.Name ? true : false);
    const user5 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[4]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[4]?.Name == el.Name ? true : false);
    const user6 = gear == true ? newarr?.filter(el => listItemUser[0]?.Travellers[5]?.Name == el.Name ? true : false) : newarr2?.filter(el => listItemUser[0]?.Travellers[5]?.Name == el.Name ? true : false);

    const getcamper4x4wei = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING CAMPER") {
            const gettata = data.filter(el => el.packingLocation == "4x4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);
    
    const getdgearcamoper = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING CAMPER") {
            const gettata = data.filter(el => el.packingLocation == "Camper" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);

    // Hiking Weight Total 
    const gethiking = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user1.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethiking2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user1.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethiking3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user1.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethiking4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user1.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);




    const gethikings = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user2.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const gethiking2s = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user2.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const gethiking3s = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user2.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const gethiking4s = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user2.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);


    const gethikingsx = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user3.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const gethiking2sx = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user3.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const gethiking3sx = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user3.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const gethiking4sx = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user3.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);


    const gethikingsxz = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user4.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gethiking2sxz = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user4.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gethiking3sxz = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user4.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gethiking4sxz = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user4.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);


    const gethikingsxza = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user5.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gethiking2sxza = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user5.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gethiking3sxza = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user5.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gethiking4sxza = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user5.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);



    const gethikingsxzaw = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user6.filter(el => el.packingLocation == "Base Weight" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gethiking2sxzaw = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user6.filter(el => el.packingLocation == "Consumables" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gethiking3sxzaw = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user6.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gethiking4sxzaw = useMemo(() => {
        let initialValue = 0
        if (country?.label == "HIKING") {
            const gettata = user6.filter(el => el.packingLocation == "Travel Bag" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    // **********CARAVAN*********** /////
    const getcaravan1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING CARAVAN") {
            const gettata = data.filter(el => el.packingLocation == "4x4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);
    const getcaravan2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING CARAVAN") {
            const gettata = data.filter(el => el.packingLocation == "Caravan" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);

    //  4x4 Boat ///////
    const get4x4boat = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING BOAT") {
            const gettata = data.filter(el => el.packingLocation == "4x4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);
    const getboat = useMemo(() => {
        let initialValue = 0
        if (country?.label == "4x4 TOWING BOAT") {
            const gettata = data.filter(el => el.packingLocation == "Boat" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [data]);

    // PLANE/TRAIN/BOAT Weight ....

    const gethikgfgfing = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethifefeking2m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethikiffng3m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethiking4m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);

    const gethidsaking4m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethiwwaking4m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);
    const gethikidasdang4m = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user1.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user1]);


    const ge1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const ge2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const ge3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const ge4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);

    const ge5 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const ge6 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);
    const ge7 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user2.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user2]);



    const ges1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const ges2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const ges3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const ges4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);

    const ges5 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const ges6 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);
    const ges7 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user3.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user3]);




    const gesa1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gesa2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gesa3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gesa4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);

    const gesa5 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gesa6 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);
    const gesa7 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user4.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user4]);


    const gesas1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gesas2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gesas3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gesas4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);

    const gesas5 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gesas6 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);
    const gesas7 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user5.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user5]);



    const gesasa1 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Carry On" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gesasa2 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Wear" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gesasa3 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Bag 1" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gesasa4 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Bag 2" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);

    const gesasa5 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Bag 3" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gesasa6 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Bag 4" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);
    const gesasa7 = useMemo(() => {
        let initialValue = 0
        if (country?.label == "AIR +") {
            const gettata = user6.filter(el => el.packingLocation == "Bag 5" ? true : false)
            return gettata?.reduce(
                (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity ? currentValue.Quantity : 1)
                , initialValue
            )
        }
    }, [user6]);













    const totalcamper = getcamper4x4wei + getdgearcamoper;
    const totalcaravan = getcaravan1 + getcaravan2;
    const totalboat = get4x4boat + getboat;
    const totalhiking = gethiking + gethiking2;
    const totalhikings = gethikings + gethiking2s;
    const totalhikingsx = gethikingsx + gethiking2sx;
    const totalhikingsxz = gethikingsxz + gethiking2sxz;
    const totalhikingsxza = gethikingsxza + gethiking2sxza;
    const totalhikingsxzaw = gethikingsxzaw + gethiking2sxzaw;
    const planuser2 = ge1 + ge2 + ge3 + ge4 + ge5 + ge6 + ge7;
    const planuser3 = ges1 + ges2 + ges3 + ges4 + ges5 + ges6 + ges7;
    const planuser4 = gesa1 + gesa2 + gesa3 + gesa4 + gesa5 + gesa6 + gesa7;
    const planuser5 = gesas1 + gesas2 + gesas3 + gesas4 + gesas5 + gesas6 + gesas7;
    const planuser6 = gesasa1 + gesasa2 + gesasa3 + gesasa4 + gesasa5 + gesasa6 + gesasa7;
    const planeuser1 = gethikgfgfing + gethifefeking2m + gethikiffng3m + gethiking4m + gethidsaking4m + gethiwwaking4m + gethikidasdang4m;
    
    return (
        <div className="weightsubcontainer">
            {country?.label == "4x4 TOWING CAMPER" ? (
                <>
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={getcamper4x4wei?.toFixed(0)} name="name" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CAMPER (kg)" value={getdgearcamoper?.toFixed(0)} name="campertotalinput" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={totalcamper?.toFixed(0)} name="name" type="number" variant="outlined" />
                </>
            ) : country?.label == "HIKING" ? (
                <>
                    {listItemUser?.length ? (
                        <>
                            {listItemUser[0]?.Travellers[0]?.Name && (
                                <>
                                    {gethiking != 0 || gethiking2 != 0 || gethiking3 != 0 || gethiking4 != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[0]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethiking?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhiking?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            
                                        </>
                                    ) : null}
                                </>
                            )}
                            {listItemUser[0]?.Travellers[1]?.Name && (
                                <>
                                    {gethikings != 0 || gethiking2s != 0 || gethiking3s != 0 || gethiking4s != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[1]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethikings?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2s?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhikings?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3s?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4s?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            
                                        </>
                                    ) : null}
                                </>
                            )}
                            {listItemUser[0]?.Travellers[2]?.Name && (
                                <>
                                    {gethikingsx != 0 || gethiking2sx != 0 || gethiking3sx != 0 || gethiking4sx != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[2]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethikingsx?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2sx?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhikingsx?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3sx?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4sx?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            
                                        </>
                                    ) : null}
                                </>
                            )}
                            {listItemUser[0]?.Travellers[3]?.Name && (
                                <>
                                    {gethikingsxz != 0 || gethiking2sxz != 0 || gethiking3sxz != 0 || gethiking4sxz != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[3]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethikingsxz?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2sxz?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhikingsxz?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3sxz?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4sxz?.toFixed(0)} name="name" type="number" variant="outlined" />
                                           
                                        </>
                                    ) : null}

                                </>
                            )}
                            {listItemUser[0]?.Travellers[4]?.Name && (
                                <>
                                    {gethikingsxza != 0 || gethiking2sxza != 0 || gethiking3sxza != 0 || gethiking4sxza != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[4]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethikingsxza?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2sxza?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhikingsxza?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3sxza?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4sxza?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            
                                        </>
                                    ) : null}

                                </>
                            )}
                            {listItemUser[0]?.Travellers[5]?.Name && (
                                <>
                                    {gethikingsxzaw != 0 || gethiking2sxzaw != 0 || gethiking3sxzaw != 0 || gethiking4sxzaw != 0 ? (
                                        <>
                                            <b style={{fontSize: '0.8rem', textTransform: 'uppercase'}}>{listItemUser[0]?.Travellers[5]?.Name}'s Weights</b><br />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE (kg)" value={gethikingsxzaw?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;+&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CONSUMABLES (kg)" value={gethiking2sxzaw?.toFixed(0)} name="name" type="number" variant="outlined" />&nbsp;=&nbsp;
                                            <TextField style={{textTransform: 'uppercase', width:'20%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK TOTAL (kg)" value={totalhikingsxzaw?.toFixed(0)} className={(totalhikings < 0 ) ? "negative" : "positive"} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'80px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethiking3sxzaw?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField style={{textTransform: 'uppercase', width:'18%',minWidth:'125px'}} disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG (kg)" value={gethiking4sxzaw?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : null}
                </>
            ) : country?.label == "4x4 TOWING CARAVAN" ? (
                <>
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={getcaravan1?.toFixed(0)} name="name" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARAVAN (kg)" value={getcaravan2?.toFixed(0)} name="name" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={totalcaravan?.toFixed(0)} name="name" type="number" variant="outlined" />
                </>
            ) : country?.label == "4x4 TOWING BOAT" ? (
                <>
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={get4x4boat?.toFixed(0)} name="name" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BOAT (kg)" value={getboat?.toFixed(0)} name="name" type="number" variant="outlined" />
                    <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={totalboat?.toFixed(0)} name="name" type="number" variant="outlined" />
                </>
            ) : country?.label == "AIR +" ? (
                <>
                    {listItemUser?.length ? (
                        <>
                            {listItemUser[0]?.Travellers[0]?.Name && (
                                <>
                                    {gethikgfgfing != 0 || gethifefeking2m != 0 || gethikiffng3m != 0 || gethiking4m != 0 || gethidsaking4m != 0 || gethiwwaking4m != 0 || gethikidasdang4m != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[0]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={gethikgfgfing?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gethifefeking2m?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={gethikiffng3m?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={gethiking4m?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={gethidsaking4m?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={gethiwwaking4m?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planeuser1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}
                            {listItemUser[0]?.Travellers[1]?.Name && (
                                <>
                                    {ge1 != 0 || ge2 != 0 || ge3 != 0 || ge4 != 0 || ge5 != 0 || ge6 != 0 || ge7 != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[1]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={ge1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={ge2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={ge3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={ge4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={ge5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={ge6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planuser2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}
                            {listItemUser[0]?.Travellers[2]?.Name && (
                                <>
                                    {ges1 != 0 || ges2 != 0 || ges3 != 0 || ges4 != 0 || ges5 != 0 || ges6 != 0 || ges7 != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[2]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={ges1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={ges2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={ges3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={ges4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={ges5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={ges6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planuser3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}

                            {listItemUser[0]?.Travellers[3]?.Name && (
                                <>
                                    {gesa1 != 0 || gesa2 != 0 || gesa3 != 0 || gesa4 != 0 || gesa5 != 0 || gesa6 != 0 || gesa7 != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[3]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={gesa1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gesa2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={gesa3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={gesa4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={gesa5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={gesa6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planuser4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}

                            {listItemUser[0]?.Travellers[4]?.Name && (
                                <>
                                    {gesas1 != 0 || gesas2 != 0 || gesas3 != 0 || gesas4 != 0 || gesas5 != 0 || gesas6 != 0 || gesas7 != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[4]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={gesas1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gesas2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={gesas3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={gesas4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={gesas5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={gesas6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planuser5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}

                            {listItemUser[0]?.Travellers[5]?.Name && (
                                <>
                                    {gesasa1 != 0 || gesasa2 != 0 || gesasa3 != 0 || gesasa4 != 0 || gesasa5 != 0 || gesasa6 != 0 || gesasa7 != 0 ? (
                                        <>
                                            <br /><b>{listItemUser[0]?.Travellers[5]?.Name}'s Weights</b><br />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (kg)" value={gesasa1?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR (kg)" value={gesasa2?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (kg)" value={gesasa3?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (kg)" value={gesasa4?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (kg)" value={gesasa5?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (kg)" value={gesasa6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={planuser6?.toFixed(0)} name="name" type="number" variant="outlined" />
                                        </>
                                    ) : null}
                                </>
                            )}

                        </>
                    ) : null}

                </>
            ) : null}
        </div>
    )
}

export default Weights