import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import reportWebVitals from './reportWebVitals';
// const stripePromise = loadStripe('pk_test_3T1aZGrzAWtOO19DzSkIRFyI');

const rootNode = document.getElementById('root');

ReactDOM.render(<App />, rootNode);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();