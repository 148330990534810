import React, { useContext,useState,useEffect,Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "../Auth";
import firebaseConfig from "../../config.js";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select, { components } from 'react-select';
import firebase from "../../config"

import data_gear from '../../food_cats.json';

import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import ClearIcon from "@material-ui/icons/Clear";
import AddCircle from '@material-ui/icons/AddCircle';

import { format } from 'date-fns'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const AddItemFormFood = ({ setAddItem, addItem, expanded, dirty, setDirty }) => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  //useState() hook captures the value from the input value
  const [name, setName] = useState("")
  const [expiry, setExpiry] = useState(null)
  const [weight, setWeight] = useState("")
  const [gearcat, setGearCat] = useState("")
  const [gearsubcat, setGearSubCat] = useState("")
  const [country, setCountry] = useState(null);
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);

  const data = useItemsUser()
  const getdata = data.length ? data[0] : []

  // handle change event of the country dropdown
  const handleCountryChange = (obj) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    if (!obj) {
      obj = {
        label: '',
        value: '',
      };
    }
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setGearCat(obj.label);
  };
 
  // handle change event of the language dropdown
  const handleLanguageChange = (obj) => {
    setLang(obj);
    setGearSubCat(obj.label);
  };
 
  /* The onSubmit function we takes the 'e' 
    or event and submits it to Firebase
    */
  const onSubmit = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    e.preventDefault();
    firebase
      .firestore()
      .collection("food")
      .add({
        Name : name,
        Weight : weight,
        Expiry : expiry,
        Category : gearcat,
        User: user1,
      })
      //.then will reset the form to nothing
      .then(() => setAddItem(!addItem), setName(""), setWeight(""), setCountry(null), setLang(null))
      .finally(() => window.location.reload())
      setAddItem(!addItem)
  };

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin:0,
      padding:0,
      zIndex:9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,
    
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setAddItem(!addItem);
  };

  const [value, setValue] = React.useState(new Date());

  function handleClr(e) {
    e.stopPropagation();
    setExpiry(null);
  }

  const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

  return (
    <div className="additem additemmain">
            <Accordion elevation={0} expanded={expanded} defaultExpanded={false} onChange={handleChange("panel")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:'none'}}
        >
        </AccordionSummary>
        <AccordionDetails className="addtripacc additemacc">
          <Typography>
          <form onSubmit={onSubmit} onChange={()=>(setDirty(true), bottomnavtarget.classList.add('cf'))}>
    
    <TextField required fullWidth id="standard-basic" label="Food Name" value={name} name="name" onChange={e => setName(e.currentTarget.value)} type="text" variant="outlined" />
    { /* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Expiry Date"}
                  inputFormat="dd/MM/yyyy"
                  closeOnSelect={true}
                  value={expiry}
                  minDate={new Date()}
                  views={['day']}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={(e) => handleClr(e)}>
                        <ClearIcon />
                      </IconButton>
                    )
                  }}
                  onChange={(newValue) => {
                    setExpiry(format(newValue, 'MM/dd/yyyy'));
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} fullWidth />}
                />
                </LocalizationProvider> */ }
    
      <Select menuPortalTarget={document.body} isSearchable={ false } fullWidth value={country}
                options={data_gear}
                onChange={handleCountryChange}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.value}
                className="select indent"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1f1',
                    primary: '#202020',
                  },
                })}
                components={{
                  ValueContainer: CustomValueContainer
                }}
                placeholder="Select Category"
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft:"11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding:"0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                  })
                }}
              />
              <div style={{ display: 'block', alignItems: 'center', position: 'relative' }}>
    <NumberFormat style={{width:'100%'}} allowNegative={false} inputProps={{ inputMode: 'decimal',}} InputProps={{endAdornment: <InputAdornment position="end">{getdata?.WeightPref == true || getdata?.WeightPref == undefined ? 'kg' : 'lb'}</InputAdornment>,}} label="Weight" customInput={TextField} variant="outlined" name="weight" value={weight} thousandSeparator={true} fixedDecimalScale="." decimalScale={3} onChange={e => setWeight(e.currentTarget.value)} />
    <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-absolute' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="For accurate results use quality kitchen scales." placement="top-start" arrow>
                  <IconButton className="tooltip tooltiprelocate" size="small" aria-label="edit" style={{ position: 'absolute', top: '-2px', right: '42px' }}><NotListedLocationIcon /></IconButton>
                </Tooltip>
              </div>
    </div><br/>
    <div className="additem-btn">
      <Button
      disableElevation
        variant="outlined"
        color="default"
        size="large"
        type="submit"
        className="btn blackbtn btnadditem"
      >
        Add Food
      </Button>
      </div>
    </form>

          </Typography>
        </AccordionDetails>
      </Accordion>   
    </div>
  );
};
export default AddItemFormFood;