import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import firebaseConfig from "../config";
import GearJson from '../gear_cats_update.json'
import BrandJson from '../brand_cats.json'
import StorageLocation from '../locations_cats.json'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardInput from './carinput'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import firebase from "../config"

const useItemsCodes = () => {
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("redeem") //access "items" collection
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsCodes = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsCodes); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const SignUp = () => {
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState(null);
  const [planvalue, setplanvalue] = useState('')
  const [togle, setTogle] = useState(false)
  const [data, setdat] = useState([])

  const checkuserexist = (email) => {
    const userfiler = data.filter(el => el.email == email.value ? true : false)
    let jauwa;
    if (userfiler.length) {
      jauwa = true
    } else {
      jauwa = false
    }
    return jauwa
  }

  const listItemCodes = useItemsCodes();

  const [isRedeemedP, setRedeemed] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { redemption, email, password } = e.target.elements;

    try {

      setTogle(true)
      const ccc = redemption.value;
      const bbb = listItemCodes[0];
      const isRedeemed = bbb[ccc]?.Redeemed;
      console.log(ccc);
      if (isRedeemed === true || isRedeemed === undefined && ccc !== 'wesley2024' ) {
        alert('The code you entered is either invalid or has already been used.');
        setRedeemed(true)
        setTogle(false)
        return;
        
      }

      


      
      if (email.value == '' && password.value == '') {
        setTogle(false)
        return;
      }
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email.value)) {
        alert("Email format is incorrect")
        setTogle(false)
        return;
      }
      if (password.value.length < 8) {
        alert("Password must be greater then 8 characters")
        setTogle(false)
        return
      }
      const heya = checkuserexist(email)
      if (heya == true) {
        alert("A user with this email already exists")
        setTogle(false)
        return;
      }

      firebaseConfig.auth().createUserWithEmailAndPassword(email.value, password.value)
            .then(id => {
              if (id) {
                const data = {
                  id: id.user.uid,
                  storageLocation: StorageLocation,
                  Brand: BrandJson,
                  planid: 'GIFT',
                  Travellers: [{Name:'', Weight:''}],
                  FourbyFourPlusCaravan: {FourbyFour_Empty: 0, FourbyFour_Payload: 0, FourbyFour_Water: 0, FourbyFour_GVM: 0, FourbyFour_FUEL: 0, FourbyFour_TOTAL: 0, id: Math.random() * 6 * 10},
                  AdventureMotorBike: {AdventureMotorBike_Empty: 0, AdventureMotorBike_GVM: 0, AdventureMotorBike_Payload: 0, AdventureMotorBike_Fuel: 0, AdventureMotorBike_Water: 0, AdventureMotorBike_Total: 0, id: Math.random() * 6 * 10},
                  BoatWeight: {BoatWeight_Empty: 0, BoatWeight_ATM: 0, BoatWeight_Payload: 0, BoatWeight_Water: 0, BoatTotal: 0, BoatforbyforTotal: 0, BoatWeight_Fuel: 0, BoatWeight_forbyfor_BoatBall: 0, BoatWeight_GCM: 0, id: Math.random() * 6 * 10},
                  Caravan: {Caravan_Empty: 0, Caravan_ATM: 0, Caravan_Payload: 0, Caravan_Water: 0, Caravan_Greywater: 0, Caravan_Total: 0, Caravan_forbyfor_Total: 0, caravan_forbyfor_caravanBall: 0, caravan_GCM: 0, id: Math.random() * 6 * 10},
                  Camper: {Camper_Empty: 0, Camper_ATM: 0, Camper_Payload: 0, Camper_Water: 0, Camper_Total: 0, Camper_CamperBall: 0, Camper_forbyfor_Total: 0, CamperplusforbyforTotal: 0, camperforbyfor_GCM: 0, id: Math.random() * 6 * 10},
                  Subcription_Status: true,
                  plancreated: new Date(),
                  planexpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                  email: id.user.email,
                  GearCategories: GearJson
                }
                if (ccc == 'wesley2024') {
                firebaseConfig
                  .firestore()
                  .collection('redeem')
                  .doc('a9d883e0-494a-11ed-9908-cd64ff907a97')
                  .update({[ccc]: {Redeemed: false}})
                } else {
                  firebaseConfig
                  .firestore()
                  .collection('redeem')
                  .doc('a9d883e0-494a-11ed-9908-cd64ff907a97')
                  .update({[ccc]: {Redeemed: true}})
                }
                firebaseConfig
                  .firestore()
                  .collection('user')
                  .doc(id.user.uid)
                  .set(data)
                  .then(() => {
                    setCurrentUser(true);
                    alert("Successfully Redeemed.")
                    history.push('/trips/index')
                    setTogle(false)
                  });
              }
            }).catch(err => {
              console.log(err)
              setTogle(false)
            });
          // No additional information was needed
          // Show a success message to your customer
          
    } catch (error) {
      alert(error);
      setTogle(false)
    }
    return setTogle(false)
  };
  // const handleSubsmit = () => {
  //   firebaseConfig
  //     .firestore() //access firestore
  //     .collection("user") //access "items" collection
  //     .onSnapshot(snapshot => {
  //       //You can "listen" to a document with the onSnapshot() method.
  //       const listItemsUsers = snapshot.docs.map(doc => ({
  //         //map each document into snapshot
  //         id: doc.id, //id and data pushed into items array
  //         ...doc.data() //spread operator merges data to id.
  //       }));
  //       if (listItemsUsers.length) {
  //         setdat(listItemsUsers)
  //       }
  //     })
  // }

  const [message, setMessage] = useState('');

  const handleChangel = event => {
    const result = event.target.value.toLowerCase();
    setMessage(result);
  }

  return (
    <>
      <div className="wrapper">
        <a href="/subscribe"><h1><FontAwesomeIcon icon={faFlask} size="lg" /> Redeem your app subscription</h1></a>
        <form onSubmit={handleSubmit}>
          <h2>Create Login</h2><br />
          <TextField required variant="outlined" onChange={handleChangel} value={message} type="text" name="redemption" placeholder="Enter your code" style={{ maxWidth: '400px', width: '100%' }}></TextField><br/>
          <TextField required variant="outlined" type="email" name="email" placeholder="Enter Your Email" style={{ maxWidth: '400px', width: '100%' }}></TextField><br />
          <TextField required variant="outlined" type="password" name="password" placeholder="Choose Your Password" style={{ maxWidth: '400px', width: '100%' }}></TextField>
          <br /><br />
          <Button
            disableElevation
            disabled={togle}
            variant="outlined"
            color="default"
            size="large"
            type="submit"
            className="btn"
            startIcon={<CheckCircleOutlineOutlinedIcon />}
          >
            Sign Up
            </Button>
        </form>
      </div>
    </>
  );
};

export default SignUp;