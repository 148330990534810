import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import firebaseConfig from "../config";
import GearJson from '../gear_cats_update.json'
import BrandJson from '../brand_cats.json'
import StorageLocation from '../locations_cats.json'
import FoodSample from '../food_sample.json'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardInput from './carinput'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faFlask } from '@fortawesome/free-solid-svg-icons'

import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';

const SignUp = () => {
  const history = useHistory()
  const [currentUser, setCurrentUser] = useState(null);
  const [planvalue, setplanvalue] = useState('')
  const [togle, setTogle] = useState(false)
  const [data, setdat] = useState([])

  useEffect(() => {
    (async function () {
      await Purchases.setLogLevel({ level: LOG_LEVEL.INFO }); // Enable to get debug logs
      await Purchases.configure({
        apiKey: "appl_bGFvvVKZxZvBgudOAaOtdkQavRD"
      });
    })();
  }, []);

  const checkuserexist = (email) => {
    const userfiler = data.filter(el => el.email == email.value ? true : false)
    let jauwa;
    if (userfiler.length) {
      jauwa = true
    } else {
      jauwa = false
    }
    return jauwa
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    try {
      setTogle(true)
      if (email.value == '' && password.value == '') {
        setTogle(false)
        return;
      }
      let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(email.value)) {
        alert("Email format is incorrect")
        setTogle(false)
        return;
      }
      if (password.value.length < 8) {
        alert("Password must be greater then 8 characters")
        setTogle(false)
        return
      }
      const heya = checkuserexist(email)
      if (heya == true) {
        alert("A user with this email already exists")
        setTogle(false)
        return;
      }

      let duration = (new URLSearchParams(window.location.search)).get("duration")

          firebaseConfig.auth().createUserWithEmailAndPassword(email.value, password.value)
            .then(id => {
              if (id) {
                const data = {
                  id: id.user.uid,
                  storageLocation: StorageLocation,
                  Brand: BrandJson,
                  planid: 'MAC',
                  Travellers: [{Name:'', Weight:''}],
                  FourbyFourPlusCaravan: {FourbyFour_Empty: 0, FourbyFour_Payload: 0, FourbyFour_Water: 0, FourbyFour_GVM: 0, FourbyFour_FUEL: 0, FourbyFour_TOTAL: 0, id: Math.random() * 6 * 10},
                  AdventureMotorBike: {AdventureMotorBike_Empty: 0, AdventureMotorBike_GVM: 0, AdventureMotorBike_Payload: 0, AdventureMotorBike_Fuel: 0, AdventureMotorBike_Water: 0, AdventureMotorBike_Total: 0, id: Math.random() * 6 * 10},
                  BoatWeight: {BoatWeight_Empty: 0, BoatWeight_ATM: 0, BoatWeight_Payload: 0, BoatWeight_Water: 0, BoatTotal: 0, BoatforbyforTotal: 0, BoatWeight_Fuel: 0, BoatWeight_forbyfor_BoatBall: 0, BoatWeight_GCM: 0, id: Math.random() * 6 * 10},
                  Caravan: {Caravan_Empty: 0, Caravan_ATM: 0, Caravan_Payload: 0, Caravan_Water: 0, Caravan_Greywater: 0, Caravan_Total: 0, Caravan_forbyfor_Total: 0, caravan_forbyfor_caravanBall: 0, caravan_GCM: 0, id: Math.random() * 6 * 10},
                  Camper: {Camper_Empty: 0, Camper_ATM: 0, Camper_Payload: 0, Camper_Water: 0, Camper_Total: 0, Camper_CamperBall: 0, Camper_forbyfor_Total: 0, CamperplusforbyforTotal: 0, camperforbyfor_GCM: 0, id: Math.random() * 6 * 10},
                  Subcription_Status: true,
                  plancreated: new Date(),
                  planexpiry: duration == 'monthly' ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                  // planexpiry: planvalue == 'price_1KZ3lmGxUje7SlyItLnBzawW' ? new Date(new Date().setMonth(new Date().getMonth() + 1)) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                  email: id.user.email,
                  GearCategories: GearJson
                }

                const foodarray = FoodSample.map((array) => ({
                  ...array,
                  User: id.user.uid,
                }));
                                
                foodarray.forEach((doc) => {
                  firebaseConfig.firestore().collection("food").doc().set(doc);
                });
                
                firebaseConfig
                  .firestore()
                  .collection('user')
                  .doc(id.user.uid)
                  .set(data)
                  .then(() => {
                    setCurrentUser(true);
                    Purchases.setEmail({ email: email.value });
                    // alert("Subscription complete. Thank you.")
                    history.push('/trips/index');
                    setTogle(false);
                  });
              }
            }).catch(err => {
              console.log(err)
              setTogle(false)
            });
          // No additional information was needed
          // Show a success message to your customer
          
    } catch (error) {
      alert("Subscription failed. Please try again.");
      setTogle(false)
    }
    return setTogle(false)
  };
  // const handleSubsmit = () => {
  //   firebaseConfig
  //     .firestore() //access firestore
  //     .collection("user") //access "items" collection
  //     .onSnapshot(snapshot => {
  //       //You can "listen" to a document with the onSnapshot() method.
  //       const listItemsUsers = snapshot.docs.map(doc => ({
  //         //map each document into snapshot
  //         id: doc.id, //id and data pushed into items array
  //         ...doc.data() //spread operator merges data to id.
  //       }));
  //       if (listItemsUsers.length) {
  //         setdat(listItemsUsers)
  //       }
  //     })
  // }

  return (
    <>
      <div className="wrapper">
        <a href="/subscribe"><h1><FontAwesomeIcon icon={faFlask} size="lg" /> Welcome</h1></a>
        <form onSubmit={handleSubmit}>
          <h2>Complete your sign-up, by creating your login</h2><br />
          <TextField required variant="outlined" type="email" name="email" placeholder="Enter Your Email" style={{ width: '100%' }}></TextField><br />
          <TextField required variant="outlined" type="password" name="password" placeholder="Choose Your Password" style={{ width: '100%' }}></TextField>
          <br /><br />
          
          <Button
            disableElevation
            disabled={togle}
            variant="outlined"
            color="default"
            size="large"
            type="submit"
            className="btn"
            startIcon={<CheckCircleOutlineOutlinedIcon />}
          >
            Sign Up
            </Button>
        </form>
      </div>
    </>
  );
};

export default SignUp;