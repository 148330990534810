import React, { useState, useEffect } from "react";
import firebase from "../../config"

import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@mui/material/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import axios from 'axios'
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotListedLocationIcon from '@material-ui/icons/Help';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import CustomeGear from './customegear'
import CustomeBrand from './custombrand'
import StorageLocation from './storagelocation'
import Vehicles from '../garage/additemform.js'

import { confirm } from "react-confirm-box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const AddItemFormUser = ({setDirty, dirty}) => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  //useState() hook captures the value from the input value
  const [name, setName] = useState("")
  const [weight, setWeight] = useState("")

  const useItemsUser = () => {
    const user = firebase.auth().currentUser;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("user") //access "items" collection
        .where("id", "==", `${user1}`)
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsUsers = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
          setItems(listItemsUsers); //items is equal to listItems
        });
      return () => unsubscribe();
    }, []);
    return items;
  };

  const listItemUser = useItemsUser();
  
  /* The onSubmit function we takes the 'e' 
  or event and submits it to Firebase
  
  */
  const onSubmit = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */

    e.preventDefault();
    if (name != '' && weight != '') {
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          Travellers: firebase.firestore.FieldValue.arrayUnion({ Name: name, Weight: weight }),
        })
        //.then will reset the form to nothing
        .then(() => setName(""), setWeight(""))
    } else {
      onEditTravaler(e)
    }
  };
  

  const [date, setdata] = useState([])
  useEffect(() => {
    setdata(listItemUser[0]?.Travellers)
    }, [listItemUser])

  const handleChange = (e, i) => {
    const clonedData = [...date];
    clonedData[i][e.target.name] = e.target.value;
    setdata(clonedData);
  }
  const [edit, setedit] = useState(false)

  const onEditTravaler = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */

    e.preventDefault();
    firebase
      .firestore()
      .collection("user")
      .doc(user1)
      .update({
        Travellers: date,
      })
      //.then will reset the form to nothing
      .then(() => setedit(false))
  };
  const onDeleteTravaler = (Name) => {
    const filterdata = date.filter(el => el.Name != Name.Name)
    firebase
      .firestore()
      .collection("user")
      .doc(user1)
      .update({
        Travellers: filterdata,
      })
      //.then will reset the form to nothing
      .then(() => setedit(false))
  }



  const [value, setValue] = React.useState(0);
  const handleChangeT = (event, newValue) => {
    const currentIndex = value;
    const formSubmitButton = document.querySelector(".dirtysubmit");
    const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");
    setValue(newValue);
    if (dirty == true) {
      if (window.confirm("Do you want to save the changes?")) {
        setValue(value);
        formSubmitButton.click();
        setDirty(false);
        bottomnavtarget.classList.remove('cf');
      } else {
        setValue(newValue);
        setDirty(false);
        bottomnavtarget.classList.remove('cf');
      }
    }
  };

  /* TRAVELLER AUTO SAVE */
  let intervalID;
  useEffect(() => {
    if (value == 1) {
      intervalID = setInterval(() => {
        const formSubmitButton = document.querySelector(".tbtn");
        if (formSubmitButton != null) {
          formSubmitButton?.click();
        }
      }, 1000);
    }
    return () => clearInterval(intervalID);
  }, [value == 1]);

  const newbilling = listItemUser?.length ? listItemUser[0] : []

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  };

  const userO = firebase.auth().currentUser;

  const deleteAcc = async (id,options) => {
    const result = await confirm("Are you sure you want to delete your account?", optionsWithLabelChange);
    if (result) {
      userO.delete().then(function() {
        window.location.href='/signout/'
      }).catch(function(error) {
        // An error happened.
      });
  }
}

const stripeuser = listItemUser[0]?.Stripe;

const [feedback, setFeedback] = useState("")

const deleteSubscription = async () => { 
  const response = await fetch('https://australia-southeast1-trip-planner-7fc5a.cloudfunctions.net/tplannersubscriptioncancel', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subID: stripeuser
    }),
  }).then((res) => res.json());

  setFeedback(response.message);

  firebase
    .firestore()
    .collection("user")
    .doc(user1)
    .update({
      Subcription_Status: false
    })
    //.then will reset the form to nothing
    // .then(() => alert("Subscription Cancel Successfully "))
}
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
  }));

  const classes = useStyles();

  const user2 = user.email;

  const handleAddFields = () => {
    const values = [...date];
        values.push({
            Name: '',
            Weight: '',
        });
        setdata(values);
        
    
  };

  const lastnumberofdata = date?.length - 1

  const [WeightPref, setWeightPref] = useState(true)
  const [DistancePref, setDistancePref] = useState(true)
  const [TipsPref, setTipsePref] = useState(true)

  const onSubmitWeightPref = (event) => {
    firebase
    .firestore()
    .collection("user")
    .doc(user1)
    .update({
      WeightPref: event.target.checked,
    })
  };
  
  const onSubmitDistancePref = (event) => {
    firebase
    .firestore()
    .collection("user")
    .doc(user1)
    .update({
      DistancePref: event.target.checked,
    })
  };

  const onSubmitTipsPref = (event) => {
    firebase
    .firestore()
    .collection("user")
    .doc(user1)
    .update({
      TipsPref: event.target.checked,
    })
  };

  return (
    <div className="additem profilefields" style={{marginBottom:'0', marginTop:'0'}}>
      <AppBar className="profile-bar" position="static" color="default">
        <Tabs value={value} key={Math.random() * 42} onChange={handleChangeT} variant="scrollable" scrollButtons="on">
          <Tab label="SUPPORT" />
          <Tab label="TRAVELLERS" />
          <Tab label="GEAR CATEGORIES" />
          <Tab label="GEAR BRANDS" />
          <Tab label="GEAR STORAGE" />
          <Tab label="VEHICLES +" />
          <Tab label="PREFERENCES" />
          <Tab label="BILLING" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <div style={{lineHeight:'1.5'}}>
      <p>If you have questions about operating the app, are experiencing technical difficulties or have suggestions to improve the app, please email <a style={{textDecoration:'underline'}} href="mailto:contact@adventurecurated.com">contact@adventurecurated.com</a>.</p>
      <p>Our team are located in Perth, Western Australia (UTC +8) and is available Monday to Friday, 9 am to 5 pm.</p>
      </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <>
        <br/>
          { /* <TextField required label="Traveller Name" value={name} name="namea" onChange={e => setName(e.currentTarget.value)} type="text" variant="outlined" />&nbsp;&nbsp;
          <NumberFormat allowNegative={false} InputProps={{ endAdornment: <InputAdornment position="end">kg</InputAdornment>, }} label="Weight" customInput={TextField} variant="outlined" name="weight" value={weight} thousandSeparator={true} fixedDecimalScale="." decimalScale={0} onChange={e => setWeight(e.currentTarget.value)} /><br /><br /> */ }
          <Typography>
            {date?.length && date?.map((el, i) => (
              <>
              <div className="yourtravellers">
                <TextField required label={'Traveller' + ' ' + (i + 1) + ' Name'} value={el.Name} name="Name" onChange={(e) => handleChange(e, i)} type="text" variant="outlined" />&nbsp;&nbsp;
                <NumberFormat allowNegative={false} InputProps={{ endAdornment: <InputAdornment position="end">{listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "kg" : "lb"}
                  </>
                    )
              })}</InputAdornment>, }} label={'Traveller' + ' ' + (i + 1) + ' Weight'} customInput={TextField} variant="outlined" name="Weight" value={el.Weight} thousandSeparator={true} fixedDecimalScale="." decimalScale={0} onChange={(e) => handleChange(e, i)} />
                &nbsp;&nbsp;<IconButton size="small" aria-label="Delete" onClick={() => onDeleteTravaler(el)}><RemoveCircleIcon /></IconButton>
              </div>
              {lastnumberofdata == i ? (
                        <Button style={{background:'#000', color:'#fff'}} className="profile-add btn blackbtn" variant="outlined" size="medium" aria-label="edit" onClick={() => handleAddFields(el)}>Add Traveller</Button>
                      ) : null}
              </>
            ))}
            <br/><br/>
            <Button
              disableElevation
              style={{display: 'none'}}
              variant="outlined"
              color="default"
              size="large"
              onClick={onSubmit}
              className="btn tbtn"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Traveller
             </Button>
          </Typography>
        </>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomeGear setDirty={setDirty} dirty={dirty} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CustomeBrand setDirty={setDirty} dirty={dirty} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StorageLocation setDirty={setDirty} dirty={dirty} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Vehicles setDirty={setDirty} dirty={dirty} />
      </TabPanel>
      <TabPanel value={value} index={6}>
      <div className="travellerscontainer" style={{padding: '0 20px'}}>
      <label className="travellerslabel">Weight Preference</label>
{listItemUser?.map(item => {
  return (
<><FormControl>
 <FormControlLabel
  control={<Switch name="WeightPref" color="primary" checked={item.WeightPref == undefined ? true : item.WeightPref}
  onChange={onSubmitWeightPref} />}
  label={item.WeightPref == undefined || item.WeightPref ? "kg" : "lb"}
 />
</FormControl></>
  )
})}
</div>
<div className="travellerscontainer" style={{padding: '0 20px'}}>
      <label className="travellerslabel">Distance Preference</label>
{listItemUser?.map(item => {
  return (
<><FormControl>
 <FormControlLabel
  control={<Switch name="DistancePref" color="primary" checked={item.DistancePref == undefined ? true : item.DistancePref}
  onChange={onSubmitDistancePref} />}
  label={item.DistancePref == undefined || item.DistancePref ? "km" : "mi"}
 />
</FormControl></>
  )
})}
</div>
<div className="travellerscontainer" style={{padding: '0 20px'}}>
      <label className="travellerslabel">Tips Preference</label>
{listItemUser?.map(item => {
  return (
<><FormControl>
 <FormControlLabel
  control={<Switch name="TipsPref" color="primary" checked={item.TipsPref == undefined ? true : item.TipsPref}
  onChange={onSubmitTipsPref} />}
  label={item.TipsPref == undefined || item.TipsPref ? "On" : "Off"}
 />
</FormControl></>
  )
})}
</div>
      </TabPanel>
      <TabPanel value={value} index={7}>
      {newbilling?.Name ? 
      <div className="travellerscontainer" style={{padding: '20px', minHeight: '16px'}}>
      <label className="travellerslabel">Name</label>
      {newbilling?.Name}
      </div> : null}
      <div className="travellerscontainer" style={{padding: '20px'}}>
      <label className="travellerslabel">Email</label>
      {user2}
      </div>
      {newbilling?.planid == 'IOS' &&
      <>
      <b>Current Plan: via In App Purchase</b><br/><br/>
      { /* Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()}</b><br/> */}
      </>
      }
      {newbilling?.planid == 'ANDROID' &&
      <>
      <b>Current Plan: via In App Purchase</b><br/><br/>
      { /* Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()}</b><br/> */ }
      </>
      }
      {newbilling?.planid == 'BETA' &&
      <>
      <b><i>Welcome BETA User!</i></b><br/>Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()}<br/><br/>
      </>
      }
      {newbilling?.planid == 'ALPHA' &&
      <>
      <b><i>Thank you ALPHA User!<br/>Expiry: 31/12/2100</i></b><br/><br/>
      </>
      }
      {newbilling?.planid == 'PROMO' &&
      <>
      <b>Current Plan: Promo<br/>
      Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()}</b><br/><br/>
      </>
      }
      {newbilling?.planid == 'GIFT' &&
      <>
      <b>Current Plan: Annual App Subscription Card<br/>
      Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()}</b><br/><br/>
      </>
      }
      {newbilling?.planid == 'WEB' &&
      <>
      <b>Current Plan: Web Purchase</b> {/* <br/>Expiry: {new Date(newbilling?.planexpiry?.seconds * 1000).toLocaleDateString()} */}<br/><br/>
      </>
      }
      { /* Member since: {new Date(newbilling?.plancreated?.seconds * 1000).toLocaleDateString()} */}
      <Button
                  disableElevation
                  variant="outlined"
                  onClick={event =>  window.location.href='/signout/'}
                  color="default"
                  size="large"
                  type="submit"
                  className="btn blackbtn"
                >
                  LOG OUT
              </Button>
              <br/><br/>
              {newbilling?.planid == 'WEB' &&
              <>
              <Button
                  disableElevation
                  variant="outlined"
                  onClick={() => deleteSubscription()}
                  color="default"
                  size="large"
                  type="submit"
                  className="btn blackbtn"
                >
                  CANCEL SUBSCRIPTION
              </Button>
              &nbsp;&nbsp;      
              </>
              }
      <Button
                  disableElevation
                  variant="outlined"
                  onClick={() => deleteAcc()}
                  size="large"
                  type="submit"
                  className="btn blackbtn"
                >
                  DELETE ACCOUNT
              </Button><br/><br/>
              {newbilling?.planid == 'WEB' &&
              <>
              {feedback ? <><span style={{display:'block', fontWeight:'bold', fontSize:'13px', marginBottom:'10px'}}>{feedback}</span></> : null}
              <small style={{color:'red'}}>Ensure you cancel your subscription prior to deleting your account. Deleting your account is final, and all records will be removed.</small>
              </>
              }
              {newbilling?.planid !== 'WEB' &&
              <>
              <small style={{color:'red'}}>Deleting your account is final, and all records will be removed.</small>
              </>
              }
      </TabPanel>
    </div>
  );
};
export default AddItemFormUser;