import React, { useContext,useState,useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "./Auth";
import firebaseConfig from "../config.js";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select from 'react-select';
import firebase from "../config"

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';

import data_gear from '../gear_cats.json';
import data_brands from '../brand_cats.json';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const style = {
  control: base => ({
    ...base,
    border: 0,
    borderBottom: '1px solid #999',
    borderRadius: 0,
    // This line disable the blue border
    boxShadow: "none",
    margin:0,
    padding:0,
  }),
  valueContainer: (provided, state) => ({
    padding: 0,
  })
}

const AddItemForm = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  //useState() hook captures the value from the input value
  const [name, setName] = useState("")
  const [owner, setOwner] = useState("")
  const [qty, setQty] = useState("")
  const [position, setPosition] = useState("")
  const [weight, setWeight] = useState("")
  const [gearcat, setGearCat] = useState("")
  const [gearsubcat, setGearSubCat] = useState("")
  const [brands, setBrands] = useState("")
  const [kitdetails, setKitDetails] = useState("")
  const [checkedA, setKitSwitch] = useState("")
  const [country, setCountry] = useState(null);
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);
 
  // handle change event of the country dropdown
  const handleCountryChange = (obj) => {
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setGearCat(obj.label);
  };
 
  // handle change event of the language dropdown
  const handleLanguageChange = (obj) => {
    setLang(obj);
    setGearSubCat(obj.label);
  };

  const handleBrandChange = (obj) => {
    setBrands(obj.label);
  };

  // Triggers on form submit
  
  const onSubmit = e => {
    e.preventDefault()
    firebase
      .firestore()
      .collection("gear")
      .add({
        Name : name,
        Owner : owner,
        Qty : qty,
        Status : position,
        Kit : checkedA,
        KitDetails: kitdetails,
        Weight : weight,
        Brand : brands,
        Category : gearcat,
        SubCategory: gearsubcat,
        User: user1,
      })
      //.then will reset the form to nothing
      .then(() => setName(""), setOwner(""), setQty(''), setWeight(""), setCountry(null), setLang(null), setBrands(null), setPosition(null))
  }

  const classes = useStyles();

  const useItems = () => {
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("gear") //access "items" collection
        .where("User", "==", user1)
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItems = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
          setItems(listItems); //items is equal to listItems
        });
      return () => unsubscribe();
    }, []);
    return items;
  };

  const [state, setState] = React.useState({
    checkedA: false,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setKitSwitch('Yes');
  };

  const deleteItem = id => {
    if (window.confirm('Are you sure to delete this record?')) {
    firebase
      .firestore()
      .collection("gear")
      .doc(id)
      .delete();
    }
  };
  
  const listItem = useItems();
  
  

  return (
    <form onSubmit={onSubmit}>    
      <Link to="/dashboard"><button>
                DASHBOARD
              </button><br/><br/>
              </Link>
              <TextField required fullWidth id="standard-basic" label="Gear Name" value={name} name="name" onChange={e => setName(e.currentTarget.value)} type="text" />
              <Select styles={style} fullWidth placeholder="Select Brand" options={data_brands} className="select" onChange={handleBrandChange} />
              <TextField fullWidth id="standard-basic" label="Owner" value={owner} name="owner" onChange={e => setOwner(e.currentTarget.value)} type="text" />
              <FormLabel component="legend">Status</FormLabel>
        <RadioGroup row aria-label="position" name="position" defaultValue="top" onChange={e => setPosition(e.currentTarget.value)}>
        <FormControlLabel value="Ready" control={<Radio color="primary" />} label="Ready" />
        <FormControlLabel value="Charge" control={<Radio color="primary" />} label="Charge" />
        <FormControlLabel value="Fill" control={<Radio color="primary" />} label="Fill" />
        <FormControlLabel value="Repair" control={<Radio color="primary" />} label="Repair" />
        <FormControlLabel value="Buy" control={<Radio color="primary" />} label="Buy" />
        <FormControlLabel value="Loaned" control={<Radio color="primary" />} label="Loaned" />
        <FormControlLabel value="Sell" control={<Radio color="primary" />} label="Sell" />
      </RadioGroup>
      <FormControlLabel control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}/>
        }
        label="Kit"
      />
      {checkedA}
      {state.checkedA == true && (
      <TextField fullWidth id="standard-textarea" label="Kit Details" value={kitdetails} name="kitdetails" onChange={e => setKitDetails(e.currentTarget.value)} multiline />
      )}
      <TextField fullWidth id="standard-basic" label="Qty" value={qty} name="qty" onChange={e => setQty(e.currentTarget.value)} type="number" />
      <TextField fullWidth id="standard-basic" label="Weight" value={weight} InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }} name="weight" onChange={e => setWeight(e.currentTarget.value)} type="number" />

      <Select styles={style} fullWidth placeholder="Select Category" value={country}
                options={data_gear}
                onChange={handleCountryChange}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.value}
                className="select"
              />
              <Select styles={style} fullWidth
                placeholder="Select Sub Category"
                value={lang}
                options={langList}
                onChange={handleLanguageChange}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.value}
                className="select"
              /><br/>
      <Button
        variant="contained"
        color="default"
        size="large"
        type="submit"
        className={classes.button}
        startIcon={<SaveAltTwoToneIcon />}
      >
        Save Gear
      </Button>
      <table className="table_display">
      <tr>
      <th>CAT / SUB CAT</th>
            <th>NAME</th>
            <th>BRAND</th>
            <th>OWNER</th>
            <th>STATUS</th>
            <th>QTY</th>
            <th>WEIGHT</th>
            <th>ACTIONS</th>
          </tr>
                {listItem.map(item => (
        <tbody key={item.id}>
                 <tr>
                 <td className="tg-c59u">{item.Category} / {item.SubCategory}</td>
            <td className="tg-ycr8">{item.Name}</td>
            <td className="tg-ycr8">{item.Brand}</td>
            <td className="tg-i81m">{item.Owner}</td>
            <td className="tg-a02x">{item.Status}</td>
            <td>{item.Qty}</td>
            <td>{item.Weight}</td>
            <td><button onClick={() => deleteItem(item.id)}>Delete</button></td>
          </tr>
        </tbody>
                ))}
                </table>
    </form>
    
    
  )
}
export default AddItemForm;