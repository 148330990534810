import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import firebase from "../../config"

import logo from '../../logo.png';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud, faCloudSlash, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { Network } from '@capacitor/network';

const TopNav = () => {

  if (firebase.auth().currentUser === null) {
    return (
      <>
      <div className="topnavigation notloggedin">
      <Link to="/" onClick={() => {window.location.href="/"}}><img src={logo} alt="Adventure Curated" /></Link>
      </div>
      </>
      );
  }

  const [network_status, setNetworkStatus] = useState(true)  
  
  Network.addListener('networkStatusChange', status => {
    console.log('Network status changed', status.connected);
    setNetworkStatus(status.connected);
  });
  
 

  useEffect(async () => {
    const network_status = await Network.getStatus();
    setNetworkStatus(network_status.connected);
    
  }, [network_status]);

  return (
    <>
    <div className="topnavigation">
      {network_status == true ? <IconButton style={{color:'green', pointerEvents:'none'}} size="small"><FontAwesomeIcon icon={faCloud} size="lg"/></IconButton> : <IconButton style={{color:'red', pointerEvents:'none'}} size="small"><FontAwesomeIcon icon={faCloudSlash} size="lg"/></IconButton>}
      <img src={logo} alt="Adventure Curated" />
      { /* <Link to="/support" onClick={() => {window.location.href="/support"}}><IconButton size="small"><FontAwesomeIcon style={{color:'#fff'}} icon={faMessageQuestion} size="lg"/></IconButton></Link>&nbsp;&nbsp; */}
      { /* <Link to="/user/index" onClick={() => {window.location.href="/user/index"}}><IconButton size="small"><FontAwesomeIcon style={{color:'#fff'}} icon={faUser} size="lg"/></IconButton></Link> */ }
      { /* <IconButton size="small" onClick={event =>  window.location.href='/support'}><FontAwesomeIcon icon={faMessageQuestion} size="lg"/></IconButton>&nbsp;&nbsp;
      <IconButton size="small" onClick={event =>  window.location.href='/user/index'}><FontAwesomeIcon icon={faUser} size="lg"/></IconButton> */ }
      </div>
    </>
  );
};
export default TopNav;