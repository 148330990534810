import React, { useContext, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
// import firebaseConfig from "../config.js";

import firebase, { storage } from "../config"

import TextField from '@material-ui/core/TextField';

import GearJson from '../gear_cats_update.json'
import BrandJson from '../brand_cats.json'
import StorageLocation from '../locations_cats.json'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddCircleIcon from '@material-ui/icons/ImageOutlined';
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import SimpleSlider from './carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageQuestion } from '@fortawesome/pro-regular-svg-icons'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
  card: {
    width: "32%",
    display: "inline-block",
    verticalAlign: "top",
    minHeight: "488px",
    marginBottom: "2%",
    borderRadius: "20px",
    position: "relative"
  },
  cardMiddle: {
    width: "32%",
    display: "inline-block",
    marginRight: "2%",
    marginLeft: "2%",
    minHeight: "488px",
    marginBottom: "2%",
    borderRadius: "20px",
    position: "relative"
  }
});

const Support = () => {

 // document.body.classList.remove('web-app');

  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  const { currentUser } = useContext(AuthContext);
  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  
  return (
    <>
      <div className="wrapper">
        <h1><FontAwesomeIcon icon={faMessageQuestion} size="lg" /></h1>
        <p style={{marginLeft: '5px', marginRight: '5px'}}>
        If you have questions about operating the app, are experiencing technical difficulties or have suggestions to improve the app, please email <a style={{textDecoration:'underline'}} href="mailto:contact@adventurecurated.com">contact@adventurecurated.com</a>.
        <br/><br/>
        Our team are located in Perth, Western Australia (UTC +8) and is available Monday to Friday, 9 am to 5 pm.
        </p>
      </div>
    </>
  );
};
export default Support;