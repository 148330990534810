import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import GearJson from '../gear_cats_update.json'
import BrandJson from '../brand_cats.json'
import StorageLocation from '../locations_cats.json'
import FoodSample from '../food_sample.json'
import GearSample from '../gear_sample.json'
import TripsSample from '../trips_sample.json'
import firebaseConfig from "../config";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcAmex } from "@fortawesome/free-brands-svg-icons"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { faUser, faTachometer } from '@fortawesome/pro-solid-svg-icons';
import googleplay from '../google-play-badge.png';
import appstore from '../app-store-badge.png';
import webapp from '../web-app-badge.png';

import subimage from '../drone.jpg';

function CheckoutForm() {
  
  // collect data from the user
  const [name, setName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [coupon, setCoupon] = useState("");
  const [promo, setPromo] = useState("");
  const [expanded, setExpanded] = useState("");
  const [promovalid, setPromoValid] = useState("");
  const [promovalidy, setPromoValidY] = useState("red");
  const [showResults, setShowResults] = useState(false);
  const [priceId, setPriceId] = useState("price_1OyQvFGxUje7SlyIy5PC7ySn");
  const [passwordShown, setPasswordShown] = useState(false);
  const [promotext, setPromoText] = useState(null);

  const [countrycode, setCountryCode] = useState("");

  const [subchoice, setSub] = useState("https://australia-southeast1-trip-planner-7fc5a.cloudfunctions.net/tplannersubscriptiontrial");

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const [processing, setProcessing] = useState(true);
  const [wait, setWait] = useState('Start a 14-day free trial');
  const [error, setError] = useState(null);

  const history = useHistory();

  const onClickCode = () => {
    setShowResults(!showResults);
  }

  const handleChange = async (event) => {
    setProcessing(false);
  };

  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleFormChangeFN = async (e) => {
    if (e.target.value.length < 2 || e.target.value == '') {
      setError('Please enter your first name');
      setProcessing(true);
    } else {
      setError(null);
    }
  };

  const handleFormChangeLN = async (e) => {
    if (e.target.value.length < 2 || e.target.value == '') {
      setError('Please enter your last name');
      setProcessing(true);
    } else {
      setError(null);
    }
  };

  const handleFormChangeEM = async (e) => {
    if (!regEmail.test(e.target.value)) {
      setError('Email format is incorrect')
      setProcessing(true);
    } else {
      setError(null);
    }
  };

  const handleFormChangePW = async (e) => {
    if (e.target.value.length < 8 || e.target.value == '') {
      setError('Password must be at least 8 characters');
      setProcessing(true);
    } else {
      setError(null);
    }
  };

  const handleFormChangePC = async (e) => {
    if (e.target.value == 'BETA') {
      setPromo('promo_1N1ienGxUje7SlyIX3wTtl5Z');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('40% OFF FOR FIRST YEAR');
    } else if (e.target.value == 'EXPO' || e.target.value == 'expo' || e.target.value == 'Expo') {
      setPromo('promo_1N3AR7GxUje7SlyIxjcqEklp');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('FIRST YEAR $30USD');
    } else if (e.target.value == 'TREDDER' || e.target.value == 'tredder' || e.target.value == 'Tredder') {
      setPromo('promo_1NfwV8GxUje7SlyIrJmBPm1I');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('50% OFF');
    } else if (e.target.value == 'BLACKFRIDAY' || e.target.value == 'blackfriday' || e.target.value == 'Blackfriday') {
      setPromo('promo_1OFpZ2GxUje7SlyIoqKcsc1D');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('BLACK FRIDAY - 50% OFF');
    } else if (e.target.value == 'TREKTOURS' || e.target.value == 'trektours' || e.target.value == 'Trektours') {
      setPromo('promo_1OjxaYGxUje7SlyIazSkS0vF');
      setExpanded('180');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('TREK TOURS AUSTRALIA - 6 MONTHS FREE');
    } else if (e.target.value == 'WESLEY2024' || e.target.value == 'wesley2024' || e.target.value == 'Wesley2024') {
      setPromo('promo_1OwfKNGxUje7SlyIUhwyAFrZ');
      setExpanded('360');
      setPromoValidY('green');
      setPromoValid('✓');
      setPromoText('WESLEY - 12 MONTHS FREE');
    } else {
      setPromo('');
      setPromoValidY('red');
      setPromoValid('✗');
      setPromoText(null);
    }
  };

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
      base: {
        color: "#fff",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        lineHeight: "35px",
        padding: "35px",
        "::placeholder": {
          color: "#a2a2a2"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
  };

  useEffect(() => {
      fetch('https://api.ipregistry.co/?key=tv18lre45zray6sz')
        .then(function (response) {
            return response.json();
        })
        .then(function (payload) {
            setCountryCode(payload.location.country.code + "D");
        });
    }
  )

  // main function
  const createSubscription = async () => {
    setProcessing(true);
    setWait('PLEASE WAIT');
    try {
      
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });

      // call the backend to create subscription
      const response = await fetch(subchoice, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod: paymentMethod?.paymentMethod?.id,
          name,
          lname,
          email,
          promo,
          expanded,
          countrycode,
          priceId
        }),
      }).then((res) => res.json());

      // confirmCardSetup

      const confirmPayment = await stripe?.confirmCardSetup(
        response.clientSecret
      );

      if (confirmPayment?.error) {
        console.log(confirmPayment.error.message);
      } else {
        setProcessing(true);
        setWait('SUBSCRIBED!')
        setError('Thank you for subscribing');

        firebaseConfig.auth().createUserWithEmailAndPassword(email, password)
            .then(id => {
              if (id) {
                const data = {
                  id: id.user.uid,
                  storageLocation: StorageLocation,
                  Brand: BrandJson,
                  planid: 'WEB',
                  Travellers: [{Name:'', Weight:''}],
                  FourbyFourPlusCaravan: {FourbyFour_Empty: 0, FourbyFour_Payload: 0, FourbyFour_Water: 0, FourbyFour_GVM: 0, FourbyFour_FUEL: 0, FourbyFour_TOTAL: 0, id: Math.random() * 6 * 10},
                  AdventureMotorBike: {AdventureMotorBike_Empty: 0, AdventureMotorBike_GVM: 0, AdventureMotorBike_Payload: 0, AdventureMotorBike_Fuel: 0, AdventureMotorBike_Water: 0, AdventureMotorBike_Total: 0, id: Math.random() * 6 * 10},
                  BoatWeight: {BoatWeight_Empty: 0, BoatWeight_ATM: 0, BoatWeight_Payload: 0, BoatWeight_Water: 0, BoatTotal: 0, BoatforbyforTotal: 0, BoatWeight_Fuel: 0, BoatWeight_forbyfor_BoatBall: 0, BoatWeight_GCM: 0, id: Math.random() * 6 * 10},
                  Caravan: {Caravan_Empty: 0, Caravan_ATM: 0, Caravan_Payload: 0, Caravan_Water: 0, Caravan_Greywater: 0, Caravan_Total: 0, Caravan_forbyfor_Total: 0, caravan_forbyfor_caravanBall: 0, caravan_GCM: 0, id: Math.random() * 6 * 10},
                  Camper: {Camper_Empty: 0, Camper_ATM: 0, Camper_Payload: 0, Camper_Water: 0, Camper_Total: 0, Camper_CamperBall: 0, Camper_forbyfor_Total: 0, CamperplusforbyforTotal: 0, camperforbyfor_GCM: 0, id: Math.random() * 6 * 10},
                  Subcription_Status: true,
                  Stripe: response.subID,
                  plancreated: new Date(),
                  planexpiry: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                  email: id.user.email,
                  GearCategories: GearJson
                }
                firebaseConfig
                  .firestore()
                  .collection('user')
                  .doc(id.user.uid)
                  .set(data)
                  .then(() => {
                    setCurrentUser(true);
                  });

                  const foodarray = FoodSample.map((array) => ({
                    ...array,
                    User: id.user.uid,
                  }));
                                  
                  foodarray.forEach((doc) => {
                    firebaseConfig.firestore().collection("food").doc().set(doc);
                  });
  
                  const geararray = GearSample.map((array) => ({
                    ...array,
                    User: id.user.uid,
                  }));
                                  
                  geararray.forEach((doc) => {
                    firebaseConfig.firestore().collection("gear").doc().set(doc);
                  });
                  
                  const tripsarray = TripsSample.map((array) => ({
                    ...array,
                    User: id.user.uid,
                    DateFrom: new Date(Date.now() + ( 3600 * 1000 * 120)),
                    DateTo: new Date(Date.now() + ( 3600 * 1000 * 240))
                  }));
                                  
                  tripsarray.forEach((doc) => {
                    firebaseConfig.firestore().collection("trips").doc().set(doc);
                  });
              }
            }).catch(err => {
              console.log(err);
            });
            history.push('/welcome')
      }
    } catch (error) {
      setProcessing(false);
      setWait('Start a 14-day free trial');
      setError('Please enter your credit card details');
      console.log(error);
    }
  };

  return (
    <>
    <div className="sub-col1" style={{width:'50%', display:'inline-block', verticalAlign:'middle'}}>
     <img className="websubimage" src={subimage} />
     </div>
     <div className="sub-col2" style={{ width:'50%', display: 'inline-block', verticalAlign:'middle'}}>
    <Box sx={{maxWidth: 600, margin: '0 auto'}}>
    <Card variant="outlined" style={{background:'#202020'}}>
    <CardContent style={{paddingTop:'0'}}>
    <h2 style={{marginTop:'0', marginBottom:'20px', display:'block', fontSize:'40px', color:'#fff'}}>Plan unforgettable trips!</h2>
    <p style={{color:'#fff', marginTop:'0', lineHeight:'1.5'}}>Prepare your gear, document your bucket list, simplify packing lists and plan to make every one of your trips a success.</p>
    { /* 7-DAY FREE TRIAL */ }
    <form className="sub_form_web">
        <RadioGroup className="subscribe-radio" defaultValue="price_1OyQvFGxUje7SlyIy5PC7ySn" onChange={(e) => { setPriceId(e.target.value); }} row name="sub_option" style={{color:'#fff'}}>
        <FormControlLabel className="weblabel" onChange={(e) => { setSub('https://australia-southeast1-trip-planner-7fc5a.cloudfunctions.net/tplannersubscriptiontrial') }} value="price_1OyQvFGxUje7SlyIy5PC7ySn" control={<Radio value="price_1OyQvFGxUje7SlyIy5PC7ySn" />} 
          labelPlacement="top" label={'Monthly $4.99' + countrycode} />
        { /* <FormControlLabel onClick={(e) => { setSub('https://australia-southeast1-trip-planner-7fc5a.cloudfunctions.net/tplannersubscriptiontrial') }} value="price_1NNUOvGxUje7SlyIAHtOJvyi" control={<Radio value="price_1NNUOvGxUje7SlyIAHtOJvyi" />} 
          labelPlacement="top" label={'QUARTERLY SUBSCRIPTION $14.99' + countrycode  + ' (SAVE 28%)'} /> */ }
        <FormControlLabel className="weblabel" onClick={(e) => { setSub('https://australia-southeast1-trip-planner-7fc5a.cloudfunctions.net/tplannersubscriptiontrial') }} value="price_1OyQwKGxUje7SlyIUJyjcPv2" control={<Radio value="price_1OyQwKGxUje7SlyIUJyjcPv2" />} 
          labelPlacement="top" label={'Annually $39.99' + countrycode  + ' (SAVE 33%)'} />
      </RadioGroup>
      <div className="inlinefield-sub" style={{display:'inline-block',width:'50%', textAlign:'left'}}>
      <TextField
        required
        variant="outlined"
        label="First Name"
        type="text"
        value={name}
        onChange={(e) => { setName(e.target.value); handleFormChangeFN(e); }}
        style={{width:'97%'}}
      /></div><div className="inlinefield-sub" style={{display:'inline-block',width:'50%', textAlign:'right'}}>
      <TextField
        required
        variant="outlined"
        label="Last Name"
        type="text"
        value={lname}
        onChange={(e) => { setLName(e.target.value); handleFormChangeLN(e); } }
        style={{width:'97%'}}
      /></div><br/>
      <TextField
        required
        variant="outlined"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => { setEmail(e.target.value); handleFormChangeEM(e); }}
        style={{width:'100%'}}
      /><br/>
      <TextField
        required
        variant="outlined"
        label="Choose Password"
        type={passwordShown ? "text" : "password"}
        value={password}
        onChange={(e) => { setPassword(e.target.value); handleFormChangePW(e); }}
        style={{width:'100%'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <VisibilityOutlinedIcon style={{color:'#fff', cursor:'pointer'}} onClick={togglePassword} />
            </InputAdornment>
          ),
        }}
      />
      <CardElement options={{hidePostalCode: true, style: cardStyle}} onChange={handleChange} />
      <div className="cclogos">
      <FontAwesomeIcon icon={faCcVisa} /> <FontAwesomeIcon icon={faCcMastercard} /> <FontAwesomeIcon icon={faCcAmex} />
      </div>
      <Button variant="outlined" onClick={onClickCode} style={{color:'#fff', float:'left', width:'33%', margin:'10px 0', borderColor:'#fff'}}>Promo Code</Button>
      { showResults ?
      <>
      <TextField
        variant="outlined"
        label="Promo Code"
        type="text"
        value={coupon}
        onChange={(e) => { setCoupon(e.target.value); handleFormChangePC(e); }}
        className="promo_code"
        style={{float: 'right', width:'60%', margin:'0', textTransform:'uppercase'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div style={{color:promovalidy}}>{promovalid}</div>
            </InputAdornment>
          ),
        }}
      /><div style={{clear:'both', color:'#ef4822', fontSize:'12px', textAlign: 'right', padding:'5px 0'}}>{promotext}</div> </>: null }
      <div style={{clear:'both'}}></div><br/>
      <Button className="subbtn" onClick={createSubscription} disabled={processing} size="large" variant="outlined">
        {wait}
      </Button>
      <span style={{color: '#fff', fontSize:'12px', marginTop:'10px', display:'block'}}>Terms • Privacy</span>
      { /* <br/>
      <a href="https://app.adventurecurated.com"><img style={{ width: '113px' }} src={webapp}></img></a> <a href="https://apps.apple.com/au/app/trip-planner/id1593605887?platform=iphone"><img style={{ width: '100px' }} src={appstore}></img></a> <a href="https://play.google.com/store/apps/details?id=com.actripplanner.app"><img style={{ width: '113px' }} src={googleplay}></img></a> */ }
      <div style={{display:'block', color:'#ff7474', fontSize:'11px', height:'40px', lineHeight:'40px'}}>
      {error}
      </div>
    </form>
    </CardContent>
    </Card>
    </Box>
    </div>
    </>
  );
}

export default CheckoutForm;