import React, { useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import firebase from "../config"
import { AuthContext } from "./Auth";
import Button from '@material-ui/core/Button';
import LoginIcon from '@mui/icons-material/Login';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { isPlatform, getPlatforms } from '@ionic/react';



const Home = () => { 

  const history = useHistory();

  // document.body.classList.add('web-app');
  // document.body.classList.add('web-app-override');
  const { currentUser } = useContext(AuthContext);

  if (isPlatform('ios') && isPlatform('hybrid')) {
    // document.body.classList.remove('web-app');
    // document.body.classList.add('ios-app');
    return (
      <div className="wrapper tp-home">
        {currentUser ? history.push('/trips/index') :
        <Redirect to="/IOS" /> }
      </div>
    );
  } else if (isPlatform('android') && isPlatform('hybrid')) {
    // document.body.classList.remove('web-app');
    // document.body.classList.add('ios-app');
    return (
      <div className="wrapper tp-home">
        {currentUser ? (
          history.push('/trips/index')
        ) : 
        <Redirect to="/ANDROID" /> }
      </div>
    );
  } else if (isPlatform('desktop') && isPlatform('hybrid')) {
    return (
      <div className="wrapper tp-home">
        {currentUser ? (
          history.push('/trips/index')
        ) :
        <Redirect to="/MACOS" /> }
      </div>
    );
  } else {
    // document.body.classList.remove('web-app');
    // document.body.classList.add('login-web');
    return (
      <div className="wrapper tp-home">
        {currentUser ? (
          history.push('/trips/index')
        ) : 
        <>
        <div className="homebuttons">
        <Button size="large" variant="outlined" startIcon={<LoginIcon />} href="/login">Login</Button><br/><br/>
        <Button size="large" variant="outlined" startIcon={<LoyaltyIcon />} href="/subscribe">Subscribe</Button>
        </div>
      </> }
      </div>
    );
  }
  
};

export default Home;