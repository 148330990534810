import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_pgxHrp4YfmDpyPHSotDyZ2L2");

const SignUp = () => {

  useEffect(() => {
    document.body.className = 'web-subscribe';
  }, []);

  { /* 
  const [countrycode, setCountryCode] = useState("");
  useEffect(() => {
    fetch('https://api.ipregistry.co/?key=tv18lre45zray6sz')
      .then(function (response) {
          return response.json();
      })
      .then(function (payload) {
          setCountryCode(payload.location.country.code + "D");
      });
  }
) */ }

  return  (
    <>
    <div className="subscribe-wrapper">
    <div className="wrapper">
        { /* <h2>7-DAY FREE TRIAL<br/>$49.99{countrycode} ANNUALLY</h2>
        <br/><br/> */ }
        <Elements stripe={stripePromise}>
        <CheckoutForm />
        </Elements>
    </div>
    </div>
    </>
);

}


export default SignUp;