import React, { useState, useEffect } from "react";
import { Redirect, Link, useHistory } from "react-router-dom";
import firebase from "../../config"
import ItemList from "./itemlist";
import AddItemForm from "./additemform";
import UpdateItem from "./updateitem";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import NotListedLocationIcon from '@material-ui/icons/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button';
import { formControlClasses } from "@mui/material";

export default () => {

  // document.body.classList.remove('web-app');
  
  // window.scrollTo(0, 0);
  
  const history = useHistory()

  if (firebase.auth().currentUser === null) {
    return <Redirect to="/" />;
  }

  const initialItemState = [
    { id: null, Name: "" }
  ];

  const [editing, setEditing] = useState(false);
  const [addItem, setAddItem] = useState(false);

  const useItemsUser = () => {
    const user = firebase.auth().currentUser;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("user") //access "items" collection
        .where('id', '==', `${user.uid}`)
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsUsers = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
          setItems(listItemsUsers); //items is equal to listItems
        });
      return () => unsubscribe();
    }, []);
    return items;
  };

  const dataT = useItemsUser()
  const getdataT = dataT.length ? dataT[0] : []

  const [currentItem, setCurrentItem] = useState(initialItemState);
  // window.scrollTo(0, 0);
  const editItem = item => {
    setCurrentItem({
      id: item.id,
      Name: item.Name,
      Bucket: item.Bucket,
      Category: item.Category,
      DateFrom: item.DateFrom,
      DateTo: item.DateTo,
      Notes: item.Notes,
      Activities: item.Activities ? item.Activities : '',
      Document: item.Document,
      Budget_KM: item.Budget_KM,
      Budget_L100KM: item.Budget_L100KM,
      Budget_MPG: item.Budget_MPG ? item.Budget_MPG : 0,
      Budget_FuelPrice: item.Budget_FuelPrice,
      Budget_CampingFees: item.Budget_CampingFees,
      Budget_Accommodation: item.Budget_Accommodation,
      Budget_FoodShopping: item.Budget_FoodShopping,
      Budget_EatingOut: item.Budget_EatingOut,
      Budget_Tours: item.Budget_Tours,
      Budget_Additional: item.Budget_Additional,
      Budget_Airfares: item.Budget_Airfares,
      Budget_HireCar: item.Budget_HireCar,
      Itinerary: item.Itinerary,
      Gear: item.Gear,
      Food: item.Food,
      Meals: item.Meals,
      GearWeight: item.GearWeight,
      FoodWeight: item.FoodWeight,
      MealWeight: item.MealWeight,
      ...item
    });
    setEditing(true);
  };

  const divElement = document.getElementById(currentItem.id);
  const getid = useHistory()

  useEffect(() => {
    const ia = getid?.location?.search
    const awa = ia?.split('?')
    if(awa?.length > 1){
      firebase
        .firestore() //access firestore
        .collection("trips") //access "items" collection
        .where("id", "==", awa[1])
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsTripsComplete = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
        if(listItemsTripsComplete?.length){
          listItemsTripsComplete.map(el => {
            editItem(el)
          })
        }
        });
    }
  }, [])

  const updateItem = ({ currentItem }, updatedItem) => {
    console.log(
      "It send the item to the updated item function:",
      updatedItem,
      currentItem.id
    );
    setEditing(false);
    firebase
      .firestore()
      .collection("trips")
      .doc(currentItem.id)
      .update(updatedItem)
      .then(() =>
        divElement.scrollIntoView())
  };

  const [expanded, setExpanded] = useState(false);

  const [dirty, setDirty] = React.useState(false);

  const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

  const headerlogo = document.querySelector(".topnavigation img");

  return (
    <div>
    {editing ?
      <>
      <div className="update-title-bar">
      <a href="/trips/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faRoute} size="lg" />{currentItem.Name}</h1></a>
      { /* <FontAwesomeIcon icon={faChevronLeft} className="new-back-button" onClick={() => {
            history.push('/trips/index')
            setEditing(false)
          }}/> */ }
      </div>
      </>
      :
      <>
      {expanded ? <div className="backchevron" onClick={() => {
            const formSubmitButton = document.querySelector(".btnadditem");
            headerlogo.classList.remove('indent');
            if (dirty == true) {
              if (window.confirm("Do you want to save the changes?")) {
              bottomnavtarget.classList.remove('cf');
              formSubmitButton.click();
              setDirty(!dirty);
              } else { setDirty(!dirty); bottomnavtarget.classList.remove('cf'); setExpanded(!expanded); } } else { setExpanded(!expanded); }
          }}><FontAwesomeIcon icon={faChevronLeft} size="lg" /></div> : null }
      <div className="update-title-bar update-title-bar-nw">
      {expanded ? null : <Button className="item-save btn whitebtn" onClick={() => (setExpanded(true), headerlogo.classList.add('indent'))}>Add Trip</Button>}
      { /* addItem ? <Button className="item-save btn greybtn" form='component-form' disableElevation
              variant="outlined"
              color="default"
              size="small"
    startIcon={<AddCircle />} type="submit">ADD TRIP</Button> : null */ }
      <a href="/trips/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faRoute} size="lg" />TRIPS</h1></a>
      </div>
      {expanded ? <AddItemForm addItem={addItem} setAddItem={setAddItem} dirty={dirty} setDirty={setDirty} expanded={expanded} /> : headerlogo?.classList.remove('indent')}
      </>}
    <div className="wrapper">
      {editing ? (
        <UpdateItem
          setEditing={setEditing}
          currentItem={currentItem}
          addItem={addItem} 
          setAddItem={setAddItem}
          updateItem={updateItem}
          editing={editing}
        />
      ) : (
        <></>
        )}
      {expanded ? null : <div className="displaygap"><ItemList addItem={addItem} editing={editing} editItem={editItem} updateItem={updateItem} /></div>}
    </div>
    </div>
  );
};