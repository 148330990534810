import React, { useState, useEffect } from "react";
import firebase from "../../config"

import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';

import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';

import Paper from '@material-ui/core/Paper';


/* 
So far we have passed the setEditing prop to index.js. 
Now we are going to pass the currentItem prop
*/
const UpdateItem = ({ setEditing, currentItem, updateItem, setWeight, editingWeight, editing }) => {
  /*
  Sets the state of the item to the current item
  */
  const user = firebase.auth().currentUser;
  const [item, setItem] = useState(currentItem);
  const [list, setList] = React.useState([item]);

  const user1 = user.uid;

  const deleteItem = Name => {
    const newarr = list[0].Travellers.filter(el => el.Name != Name)
    firebase
      .firestore()
      .collection("user")
      .doc(user1)
      .update({
        Travellers: newarr,
      })
      .then(() => {
        setEditing(false)
        alert("User deleted")
      })
  };

  const deleteItemWeight = id => {
    const newarr = list[0].FourbyFourPlusCaravan.filter(el => el.id != id)
    firebase
      .firestore()
      .collection("user")
      .doc(user1)
      .update({
        FourbyFourPlusCaravan: newarr,
      })
      .then(() => {
        setWeight(false)
        alert("Weight deleted")
      })
  };


  /*
  Side effect is that without UseEffect if you start editing one item, 
  then try to switch to another item, nothing will happen.
  The component is already open, and although the state 
  on the parent has changed, it's not registered down to the props.
  We want to let the UpdateItem form component know the props have changed.
  With the Effect Hook, we create a callback function that updates the item 
  state with the new prop thats being sent through.
  */
  useEffect(() => {
    setItem(currentItem);
    console.log("useEffect passes the currentItem: ", currentItem);
  }, [currentItem]);

  const onSubmit = e => {
    e.preventDefault();
    console.log("onSubmit passes the id and items", item);
    updateItem({ currentItem }, item);
    document.getElementById(item.id).scrollIntoView();
  };
  return (
    <>
      {editing == true ? (
        <Paper elevation={3}>
          <div id="updateItem" className="updateitem">
            <h2>Update Travellers</h2><br />
            {item && item.Travellers && item.Travellers.length > 0 && item.Travellers.map((tit, index) => {
              return (
                <>
                  <span key={index}>
                    <TextField id="standard-basic" label="Name" value={tit.Name} name="Name" type="text" variant="outlined" onChange={e => { tit.Name = e.target.value; setList([...list]); }} />&nbsp;&nbsp;
                <NumberFormat allowNegative={false} label="Quantity" customInput={TextField} variant="outlined" name="Quantity" value={tit.Weight} thousandSeparator={true} fixedDecimalScale="." decimalScale={2} fixedDecimalScale={true} onChange={e => { tit.Weight = e.target.value; setList([...list]); }} />
                    <Button
                      disableElevation
                      variant="outlined"
                      color="secondary"
                      size="large"
                      className="btn deletebtn"
                      onClick={() => deleteItem(tit.Name)}
                    >
                      Delete {tit.Name}
                    </Button><br /></span>
                </>
              )
            })}
            <form onSubmit={onSubmit}>
              <Button
                disableElevation
                variant="outlined"
                color="default"
                size="large"
                type="submit"
                className="btn"
                startIcon={<SaveAltTwoToneIcon />}
              >
                Update Travellers
      </Button>&nbsp;&nbsp;
      <Button
                disableElevation
                variant="outlined"
                color="default"
                size="large"
                type="submit"
                className="btn"
                onClick={() => {
                  setEditing(false)
                }}
                startIcon={<CancelTwoToneIcon />}
              >
                Cancel
      </Button>
            </form>
          </div>
        </Paper>
      ) : null}
    </>

  );
};
export default UpdateItem;