import React, { useContext,useState,useEffect } from "react";
import { Redirect, Link,useHistory } from "react-router-dom";
import { AuthContext } from "../Auth";
import firebaseConfig from "../../config.js";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Select from 'react-select';
import firebase from "../../config"
import ItemList from "./itemlist";
import AddItemForm from "./additemform";
import UpdateItem from "./updateitem";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCampground } from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

export default () => {

  // document.body.classList.remove('web-app');
  
  // window.scrollTo(0, 0);
  
  if (firebase.auth().currentUser === null) {
    return <Redirect to="/" />;
  }
  
  const initialItemState = [
    { id: null, Name: "" }
  ];

  const [editing, setEditing] = useState(false);
  const [addItem, setAddItem] = useState(false);

  const [currentItem, setCurrentItem] = useState(initialItemState);

  const editItem = item => {
    setCurrentItem({
      id: item.id,
      Name : item.Name,
      Owner : item.Owner,
      Qty : item.Qty,
      Status : item.Status,
      RepairNotes : item.RepairNotes,
      LoanNotes : item.LoanNotes,
      Kit : item.Kit,
      KitDetails : item.KitDetails,
      Weight : Number(item.Weight),
      ReplaceDate : item.ReplaceDate,
      ProofValue : item.ProofValue,
      ProofReceipt : item.ProofReceipt,
      ProofReceiptName : item.ProofReceiptName,
      WarrantyExpiry : item.WarrantyExpiry,
      ProofPhoto : item.ProofPhoto,
      ProofPhotoName : item.ProofPhotoName,
      ProofManual : item.ProofManual,
      ProofManualName : item.ProofManualName,
      Brand : item.Brand,
      BrandId : item.BrandId ?? null,
      Category : item.Category,
      CategoryId: item.CategoryId ?? null,
      SubCategory: item.SubCategory,
      SubCategoryId: item.SubCategoryId ?? null,
      StorageLocation : item.StorageLocation,
      StorageLocationId: item.StorageLocationId ?? null,
    });
    setEditing(true);
    window.scrollTo(0, 0);
  };

  const divElement = document.getElementById(currentItem.id);

  const getid = useHistory()

  useEffect(() => {
    const ia = getid?.location?.search
    const awa = ia?.split('?')
    if(awa?.length > 1){
      firebase
        .firestore() //access firestore
        .collection("gear") //access "items" collection
        .where("id", "==", awa[1])
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsTripsComplete = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
        if(listItemsTripsComplete?.length){
          listItemsTripsComplete.map(el => {
            editItem(el)
          })
        }
        });
    }
  }, [getid])

  const useItemsUser = () => {
    const user = firebase.auth().currentUser;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("user") //access "items" collection
        .where('id', '==', `${user.uid}`)
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsUsers = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
          setItems(listItemsUsers); //items is equal to listItems
        });
      return () => unsubscribe();
    }, []);
    return items;
  };

  const dataT = useItemsUser()
  const getdataT = dataT.length ? dataT[0] : []
  
  const updateItem = ({ currentItem }, updatedItem) => {
    // setEditing(false);
    firebase
      .firestore()
      .collection("gear")
      .doc(currentItem.id)
      .update(updatedItem)
      // divElement.scrollIntoView())
  };

  const [expanded, setExpanded] = useState(false);

  const history = useHistory()

  const [dirty, setDirty] = React.useState(false);

  const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");
  
  const headerlogo = document.querySelector(".topnavigation img");

  return (
    <div>
    {editing ?
      <>
      <div className="update-title-bar">
      <a href="/gear/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faCampground} size="lg" />{currentItem.Name}</h1></a>
      </div>
      </>
      :
      <>
      {expanded ? <div className="backchevron" onClick={() => {
            const formSubmitButton = document.querySelector(".btnadditem");
            headerlogo.classList.remove('indent');
            if (dirty == true) {
              if (window.confirm("Do you want to save the changes?")) {
              bottomnavtarget.classList.remove('cf');
              formSubmitButton.click();
              setDirty(!dirty);
              } else { setDirty(!dirty); bottomnavtarget.classList.remove('cf'); setExpanded(!expanded); } } else { setExpanded(!expanded); }
          }}><FontAwesomeIcon icon={faChevronLeft} size="lg" /></div> : null }
      <div className="update-title-bar update-title-bar-nw">
      {expanded ? null : <Button className="item-save btn whitebtn" onClick={() => (setExpanded(true), headerlogo.classList.add('indent')) }>Add Gear</Button>}
      <a href="/gear/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faCampground} size="lg" />GEAR</h1></a> { /* <span className="goright"><Tooltip className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? null : 'notooltip'} leaveTouchDelay={3000} enterTouchDelay={50} title="Tips ?s are located throughout Trip Planner for quick reference. You can hide the tips ?s within the Preferences tab of your Profile. Profile is accessible via the far right icon in the header menu." placement="right-start" arrow>
      <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton></Tooltip></span> */ }
      </div>
      {expanded ? <AddItemForm addItem={addItem} setAddItem={setAddItem} dirty={dirty} setDirty={setDirty} expanded={expanded} /> : headerlogo?.classList.remove('indent')}
      </>}
    <div className="wrapper">
      {editing ? (
        <UpdateItem
        setEditing={setEditing}
        currentItem={currentItem}
        addItem={addItem} 
        setAddItem={setAddItem}
        updateItem={updateItem}
        editing={editing}
      />
      ) : (
        <></>
      )}
      {expanded ? null : <div className="displaygap"><ItemList addItem={addItem} editing={editing} editItem={editItem} updateItem={updateItem} /></div>}
    </div>
    </div>
  );
};