import React from "react";
import Button from '@material-ui/core/Button';
import LoginIcon from '@mui/icons-material/Login';
import googleplay from '../google-play-badge.png';
import appstore from '../app-store-badge.png';
import webapp from '../web-app-badge.png';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessageQuestion } from '@fortawesome/pro-regular-svg-icons'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import logo from '../logo.png';

// document.body.classList.add('web-app');

const HomeLogin = () => {
    return (
        <div className="subscribe-wrapper">
        <div className="wrapper">
        <Box sx={{ maxWidth: 600, textAlign: 'center', margin: 'auto' }}>
        <Card variant="outlined">
        <CardContent style={{color: '#fff', lineHeight:'24px'}}>
        <p>Welcome! To start, bookmark the Web App (<a href="https://app.adventurecurated.com">app.adventurecurated.com</a>) and download the iOS or Android App on your smartphone and tablet.</p>
        <p><a href="https://app.adventurecurated.com"><img style={{ width: '170px' }} src={webapp}></img></a> <a href="https://apps.apple.com/au/app/trip-planner/id1593605887?platform=iphone"><img style={{ width: '150px' }} src={appstore}></img></a> <a href="https://play.google.com/store/apps/details?id=com.actripplanner.app"><img style={{ width: '170px' }} src={googleplay}></img></a></p>
        <p>Next, log in on any app with your email and chosen password and start to plan your bucket list and adventures!</p>
        <p>If you have any questions, please get in touch via the contact page on our website or the support page of the app&nbsp;&nbsp;<FontAwesomeIcon icon={faMessageQuestion} size="rg"/></p>
        </CardContent>
        </Card>
        </Box>
        </div>
        </div>
    );
};

export default HomeLogin;