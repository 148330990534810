import React, { useContext, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./Auth";
import firebaseConfig from "../config.js";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import LoginIcon from '@mui/icons-material/Login';
import PasswordIcon from '@mui/icons-material/LockReset';
import { isPlatform } from '@ionic/react';

const LogIn = () => {
  // document.body.classList.add('web-app');
  // document.body.classList.add('web-app-override');
  
  let history = useHistory();

  const { currentUser } = useContext(AuthContext);

  const [message, setmessage] = useState(null)
  const [emaila, setemaila] = useState('')
  const [messages, setMessages] = useState(null)
  const [fpass, setfpass] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;

    try {
      const userCredential = await firebaseConfig.auth().signInWithEmailAndPassword(
        email.value,
        password.value
      );

      const user = userCredential.user;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      setmessage(errorMessage)
      console.log("errorCode:", errorCode, "errorMessage:", errorMessage);
    }
  }
    
  
  const resetpass = (e) => {
    if (emaila != '') {
      firebaseConfig.auth().sendPasswordResetEmail(emaila).then(() => {
        setMessages('An email has been sent with instructions to reset your password.')
      }).catch((err) => {
        setMessages(err.message)
      })
    }
  }

  // if (isPlatform('ios') && isPlatform('hybrid')) {
  //  document.body.classList.remove('web-app');
  //  document.body.classList.add('ios-app');
  // }

  useEffect(() => {
    if (!currentUser) {
    document.body.style.padding = '0';
    document.body.style.background = '#202020';
    } else {
      null
    }
}, [])


if (!currentUser) {

  return (
    
    <div className="wrapper front-wrapper" style={{textAlign:'left'}}>
      {fpass == false ? (
        
        <>
          <form onSubmit={handleSubmit} className="login-form">
            <TextField InputLabelProps={{ required: false }} style={{margin:0}} required fullWidth id="email" label="Email" name="email" type="email" variant="outlined" />
            <TextField InputLabelProps={{ required: false }} required fullWidth id="password" label="Password" name="password" type="password" variant="outlined" />
            <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              type="submit"
              className="btn"
              style={{backgroundColor: '#fff', color:'#202020', paddingLeft:'20px', paddingTop:'15px', paddingBottom:'15px', marginBottom:'10px', width:'400px', border:'0'}}
            >
              Login
            </Button><br/>
            {message ? <>
            <div style={{ color: 'red', padding: '0 0 10px 0', fontSize: '12px', fontWeight: 'normal', maxWidth:'600px', textAlign:'center' }}>{message}</div>
            </> : null}
            { /* <p style={{cursor: 'pointer'}} onClick={() => setfpass(true)}>Forgot password?</p> */ }
            <Button style={{width:'100%'}} size="medium" variant="outlined" onClick={() => setfpass(true)}>Forgot Password?</Button>
            { /* <Button size="medium" variant="outlined" style={{float:'right'}} href="../">Go Back</Button> */ }
            {(() => {
        if (isPlatform('ios') && isPlatform('hybrid')) {
          return (
            <Button className="loginsubscribe" size="medium" variant="outlined" style={{color:'#fff', padding:'0', border: '0', borderRadius:'0', marginTop: '10px'}} href="/ios">subscribe</Button>
          )
        } else if (isPlatform('android') && isPlatform('hybrid')) {
          return (
            <Button className="loginsubscribe" size="medium" variant="outlined" style={{color:'#fff', padding:'0', border: '0', borderRadius:'0', marginTop: '10px'}} href="/android">subscribe</Button>
          )
        } else if (isPlatform('desktop') && isPlatform('hybrid')) {
          return (
            <Button className="loginsubscribe" size="medium" variant="outlined" style={{color:'#fff', padding:'0', border: '0', borderRadius:'0', marginTop: '10px'}} href="/macos">subscribe</Button>
          )
        } else {
          return (
            <Button className="loginsubscribe" size="medium" variant="outlined" style={{color:'#fff', padding:'0', border: '0', borderRadius:'0', marginTop: '10px'}} href="/subscribe">subscribe</Button>
          )
        }
      })()}
            <div style={{clear:'both'}}></div>
          </form>
        </>
        
      ) : (
          <>
            <div className="login-form">
            <TextField InputLabelProps={{ required: false }} required fullWidth id="standard-basic" label="Email" name="email" type="email" variant="outlined" onChange={(e) => setemaila(e.target.value)} />
            
            <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              onClick={() => resetpass()}
              className="btn"
              style={{backgroundColor: '#fff', color:'#202020', paddingLeft:'20px', paddingTop:'15px', paddingBottom:'15px', marginBottom:'10px', width:'400px', border:'0'}}
            >
              Recover Password
            </Button>
      {messages ? <>
        <div style={{ color: 'white', padding: '0 0 10px 0', fontSize: '12px', fontWeight: 'normal', maxWidth:'600px', textAlign:'center' }}>{messages}</div>
      </> : null }
            <Button size="medium" variant="outlined" style={{color:'#fff', padding:'0', border: '0', borderRadius:'0', marginTop: '10px'}} onClick={() => setfpass(false)}>Return to Login</Button>
            </div>
          </>
        )}
    </div>

  );
} else {
  window.location.href='/trips/index'
};
};

export default LogIn;