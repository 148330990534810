import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {isMobile} from 'react-device-detect';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Modify this line to set the enviroment
// environment -> 'PRE', 'PRO'
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export let environment = '';

const firebaseConfig = {
  apiKey: "AIzaSyCl_BrKQAVYaT-Hjyfu3cYxDZgna1Jidt4",
  authDomain: "trip-planner-7fc5a.firebaseapp.com",
  databaseURL: "https://trip-planner-7fc5a-default-rtdb.firebaseio.com",
  projectId: "trip-planner-7fc5a",
  storageBucket: "trip-planner-7fc5a.appspot.com",
  messagingSenderId: "197843992142",
  appId: "1:197843992142:web:3a69a822035cff382d4d1f",
  measurementId: "G-WF1GZKMWE2",
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ experimentalForceLongPolling: false, cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED, merge: true });

/*
  firebase.firestore().enablePersistence().catch((err) => {
    if (err.code == 'failed-precondition') {
      console.log(err);
   } else if (err.code == 'unimplemented') {
      console.log(err);
   } else {
    alert(err.code);
   }
  });
*/


// firebase.analytics();
export const storage = firebase.storage(); 
export default firebase
