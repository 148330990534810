import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import firebase from "../../config"
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useItemsUser = () => {
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    const user = firebase.auth().currentUser;
    const user1 = user.uid;
    useEffect(() => {
        const unsubscribe = firebase
            .firestore() //access firestore
            .collection("user") //access "items" collection
            .where("id", "==", `${user1}`)
            .onSnapshot(snapshot => {
                //You can "listen" to a document with the onSnapshot() method.
                const listItemsUsers = snapshot.docs.map(doc => ({
                    //map each document into snapshot
                    id: doc.id, //id and data pushed into items array
                    ...doc.data() //spread operator merges data to id.
                }));
                setItems(listItemsUsers); //items is equal to listItems
            });
        return () => unsubscribe();
    }, []);
    return items;
};
const StorageLocation = ({setDirty, dirty}) => {
    const user = firebase.auth().currentUser;
    const [edit, setEdit] = useState(false)
    const user1 = user.uid;
    const [customgear, setcustomgear] = useState([{
        label: '',
        value: '',
        id: uuidv4(),
    }
    ])
    const [customgearUpdate, setcustomgearUpdate] = useState(null)
    const handleAddFields = () => {
        const values = [...customgear];
        values.push({
            label: '',
            value: '',
            id: uuidv4(),
        });
        setcustomgear(values);
    };
    const handleRemoveFields = (index) => {
        const newData = customgear.filter((el, i) => i != index)
        setcustomgear(newData)
    }


    const onChangeHandler = (e, i, index) => {
        const values = [...customgear];
        if (e.target.name === "storage") {
            values[i].label = e.target.value;
            values[i].value = e.target.value;
        }
        if (e.target.name === "value") {
            values[i].subCategory[index].value = e.target.value;
            values[i].subCategory[index].label = e.target.value;
        }
        setcustomgear(values)
    }

    const data = useItemsUser()

    useEffect(() => {
        setcustomgearUpdate(data[0]?.storageLocation)
    }, [data])
    const handleRemoveFields2 = (index) => {
        const newData = customgearUpdate?.filter((el, i) => i != index)
        setcustomgearUpdate(newData)
    }
    const handleAddFields2 = () => {
        const values = [...customgearUpdate];
        values.push({
            label: '',
            value: '',
            id: uuidv4(),
        });
        setcustomgearUpdate(values);
    };
    
    const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

    const onChangeHandler2 = (e, i, index) => {
        setDirty(true);
        bottomnavtarget.classList.add('cf');
        const values = [...customgearUpdate];
        if (e.target.name === "storage") {
            values[i].label = e.target.value;
            values[i].value = e.target.value;
        }
        if (e.target.name === "value") {
            values[i].subCategory[index].value = e.target.value;
            values[i].subCategory[index].label = e.target.value;
        }
        setcustomgearUpdate(values)
    }
    const SubmitHandler = (e) => {
        e.preventDefault()
        if (data[0].storageLocation.length) {
            customgear.map(el => {
                data[0].storageLocation.push(el)
            })
        }
        customgearUpdate?.sort(function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        firebase
            .firestore()
            .collection("user")
            .doc(user1)
            .update({
                storageLocation: customgearUpdate
            }).then(() => {
                setDirty(false)
                alert('Locations Updated')
            }
            )
    }
 
    return (
        <form onSubmit={SubmitHandler}>
            {customgearUpdate?.length && customgearUpdate?.map((el, i) => {
                return (
                    <>
                    <span className="profile-primary" style={{marginTop:'0'}}>
                        <TextField
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: '100%' }}
                            id="standard-basic"
                            label="Storage Location"
                            value={el.label}
                            required={true}
                            name="storage"
                            onChange={(e) => onChangeHandler2(e, i)}
                            type="text"
                            variant="outlined"
                            inputProps={{style: {textTransform: 'uppercase'}}}
                        />
                        <IconButton size="small" aria-label="edit" onClick={() => handleRemoveFields2(i)}><RemoveCircleIcon /></IconButton>
                        </span>
                    </>
                )
            })}
            
            <Button size="large" className="btn stickybtn mobilesplitbtn blackbtn" variant="outlined" aria-label="edit" onClick={() => handleAddFields2()}>Add Location</Button>
            <br/><br/>
            <Button
                disableElevation
                variant="outlined"
                color="default"
                size="large"
                type="submit"
                className="btn stickybtn stickybtn2 stickybtn3 mobilesplitbtn blackbtn dirtysubmit"
            >
                UPDATE LOCATIONS
             </Button>
        </form>
    )
}
export default StorageLocation