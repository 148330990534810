import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../../config"
import AddItemForm from "./additemform";
// import UpdateItem from "./updateitem";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMonster } from '@fortawesome/pro-solid-svg-icons'

export default () => {

  // window.scrollTo(0, 0);
  
  document.body.classList.remove('web-app');

  if (firebase.auth().currentUser === null) {
    return <Redirect to="/" />;
  }
  
  const initialItemState = [
    {  }
  ];

  const useItemsUser = () => {
    const user = firebase.auth().currentUser;
    const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
    useEffect(() => {
      const unsubscribe = firebase
        .firestore() //access firestore
        .collection("user") //access "items" collection
        .where('id', '==', `${user.uid}`)
        .onSnapshot(snapshot => {
          //You can "listen" to a document with the onSnapshot() method.
          const listItemsUsers = snapshot.docs.map(doc => ({
            //map each document into snapshot
            id: doc.id, //id and data pushed into items array
            ...doc.data() //spread operator merges data to id.
          }));
          setItems(listItemsUsers); //items is equal to listItems
        });
      return () => unsubscribe();
    }, []);
    return items;
  };

  const dataT = useItemsUser()
  const getdataT = dataT.length ? dataT[0] : []

  return (
    <>
    <div className="update-title-bar update-title-bar-nw">
      <a href="/trips/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faTruckMonster} size="lg" />VEHICLES +</h1></a>
    </div>
      
    <div className="wrapper">
    <div className="trip-add trip-add-vehicles">
        <AddItemForm />
    </div>
    </div>
    </>
  );
};