import React, { useState, useEffect, useRef } from "react";
import firebase from "../../config"

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import Tooltip from '@mui/material/Tooltip';
import NotListedLocationIcon from '@material-ui/icons/Help';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Box from "@material-ui/core/Box";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMonster } from '@fortawesome/sharp-solid-svg-icons'
import { faCaravanSimple } from '@fortawesome/pro-solid-svg-icons'
import { faTrailer } from '@fortawesome/sharp-solid-svg-icons'
import { faMotorcycle } from '@fortawesome/sharp-solid-svg-icons'
import { faShip } from '@fortawesome/pro-solid-svg-icons'
import { faWeightScale } from '@fortawesome/pro-solid-svg-icons'

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const AddItemFormGarage = ({setDirty, dirty}) => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  //useState() hook captures the value from the input value
  // const [name, setName] = useState("")
  // const [weight, setWeight] = useState("")



  const listItemUser = useItemsUser();
  const [fourbyfourempty, setFourbyFourEmpty] = useState(0)
  const [fourbyfourGVM, setFourbyFourGVM] = useState(0)
  const [fourbyfourFuel, setFourbyFourFuel] = useState(0)
  const [fourbyfourwater, setFourbyFourWater] = useState(0)
  const forbyfortotal = parseInt(Number(fourbyfourempty) + Number(fourbyfourFuel) + Number(fourbyfourwater));

  const fourbyfourpayload = parseInt(Number(fourbyfourGVM) - Number(fourbyfourempty));
  const fourbyfourremaining = parseInt(Number(fourbyfourGVM) - Number(forbyfortotal));
  const [camperforbyfor_CamperBall, setcamperforbyfor_CamperBall] = useState('')

  const camperforbyfor_Total = forbyfortotal;

  // camper Weight
  const [camperempty, setcamperEmpty] = useState('')
  const [camperATM, setcamperATM] = useState('')
  const [camperwater, setcamperWater] = useState("")

  const forbyfortotalplusball = parseInt(Number(forbyfortotal) + Number(camperforbyfor_CamperBall));
  const camperpayload = parseInt(Number(camperATM) - Number(camperempty));
  const camper_Total = parseInt(Number(camperempty) + Number(camperwater));
  const camperremaining = parseInt(Number(camperATM) - Number(camper_Total));
  const campernoballweight = parseInt(Number(camper_Total) - Number(camperforbyfor_CamperBall));

  // 4x4 + Camper Weight
  const [camperforbyfor_GCM, setcamperforbyfor_GCM] = useState(0)


  const camper4x4_Total = parseInt(Number(forbyfortotal) + Number(camperforbyfor_CamperBall));
  const camperminusball_Total = parseInt(Number(camper_Total) - Number(camperforbyfor_CamperBall));
  const campertotal = parseInt(Number(camper4x4_Total) + Number(camperminusball_Total));
  const camperremaining_Total = Number(camperforbyfor_GCM) - Number(campertotal);

  // Boat Weight 4x4
  const [Boatforbyfor_BoatBall, setBoatforbyfor_BoatBall] = useState('')

  const boatforbyfor_Total = forbyfortotal;

  // Boat Weight
  const [Boatempty, setBoatEmpty] = useState('')
  const [BoatATM, setBoatATM] = useState('')
  // const [Boatpayload, setBoatPayload] = useState("")
  const [Boatwater, setBoatWater] = useState("")
  const [Boatfuel, setBoatFuel] = useState("")
  const Boatpayload = parseInt(Number(BoatATM) - Number(Boatempty));

  const Boat_Total = parseInt(Number(Boatempty) + Number(Boatwater) + Number(Boatfuel));
  const boatremaining = parseInt(Number(BoatATM) - Number(Boat_Total));
  const boatnoballweight = parseInt(Number(Boat_Total) - Number(Boatforbyfor_BoatBall));

  // Boat + 4x4
  // const fourbyfourremaining = Number(fourbyfourGVM) - Number(forbyfortotal);
  const [boatforbyfor_GCM, setboatforbyfor_GCM] = useState(0)
  // const boattotal = Number(boatforbyfor_Total) + Number(Boat_Total) + Number(boatforbyfor_GCM)

  const boatminusball_Total = parseInt(Number(Boat_Total) - Number(Boatforbyfor_BoatBall));
  // caravan 4x4 Weights

  const [caravanforbyfor_caravanBall, setcaravanforbyfor_BoatBall] = useState('')

  const caravanforbyfor_Total = forbyfortotal;


  // caravan Weights
  const [caravanempty, setcaravanEmpty] = useState('')
  const [caravanATM, setcaravanATM] = useState('')
  // const [caravanpayload, setcaravanPayload] = useState("")
  const [caravanwater, setcaravanWater] = useState("")
  const [caravangrey, setcaravanGrey] = useState("")

  const caravanpayload = parseInt(Number(caravanATM) - Number(caravanempty));
  const caravan_Total = parseInt(Number(caravanempty) + Number(caravanwater) + Number(caravangrey));
  const caravannoballweight = parseInt(Number(caravan_Total) - Number(caravanforbyfor_caravanBall));
  const forbyfortotalcaraplusball = parseInt(Number(forbyfortotal) + Number(caravanforbyfor_caravanBall));
  const boattotal = parseInt(Number(forbyfortotalcaraplusball) + Number(boatminusball_Total));
  const boatremaining_Total = parseInt(Number(boatforbyfor_GCM) - Number(boattotal));
  // Caravan + 4x4
  const [caravanforbyfor_GCM, setcaravanforbyfor_GCM] = useState(0)
  // const caravantotal = Number(caravanforbyfor_Total) + Number(caravan_Total) + Number(caravanforbyfor_GCM)
  const caravanremaining = parseInt(Number(caravanATM) - Number(caravan_Total));

  const caravanminusball_Total = parseInt(Number(caravan_Total) - Number(caravanforbyfor_caravanBall));

  const caravantotal = parseInt(Number(forbyfortotalcaraplusball) + Number(caravannoballweight));

  const caravanremaining_Total = parseInt(Number(caravanforbyfor_GCM) - Number(caravantotal));

  // OTHER

  const [adventureMotorbile_Empty, setadventureMotorbile_Empty] = useState('')
  const [adventureMotorbile_GVM, setadventureMotorbile_GVM] = useState('')
  // const [adventureMotorbile_Payload, setadventureMotorbile_Payload] = useState("")
  const [adventureMotorbile_Fuel, setBoatsetadventureMotorbile_Fuel] = useState("")
  const [adventureMotorbile_Water, setBoatsetadventureMotorbile_Water] = useState("")
  const adventureMotorbile_Payload = parseInt(Number(adventureMotorbile_GVM) - Number(adventureMotorbile_Empty));
  const adventure_Total = parseInt(Number(adventureMotorbile_Empty) + Number(adventureMotorbile_Water) + Number(adventureMotorbile_Fuel));
  const advmotoremaining = parseInt(Number(adventureMotorbile_GVM) - Number(adventure_Total));

  const [torque, setTorque] = useState(0)
  const [power, setPower] = useState(0)
  const [powerhp, setPowerHP] = useState(0)

  const onSubmitAdventureMotorbike = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    e.preventDefault();
      const id = Math.random() * 6 * 1000
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          AdventureMotorBike: {
            AdventureMotorBike_Empty: adventureMotorbile_Empty,
            AdventureMotorBike_GVM: adventureMotorbile_GVM,
            AdventureMotorBike_Payload: adventureMotorbile_Payload,
            AdventureMotorBike_Fuel: adventureMotorbile_Fuel,
            AdventureMotorBike_Water: adventureMotorbile_Water,
            AdventureMotorBike_Total: adventure_Total,
            id: id
          }
        }, { merge: true })
        .then(() => {
          onSubmitWeights(e)
        })
  };

  const onSubmitBoatWeight = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    e.preventDefault();
      const id = Math.random() * 6 * 1000
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          BoatWeight: {
            BoatWeight_Empty: Boatempty,
            BoatWeight_ATM: BoatATM,
            BoatWeight_Payload: Boatpayload,
            BoatWeight_Water: Boatwater,
            BoatTotal: Boat_Total,
            BoatforbyforTotal: boattotal,
            BoatWeight_Fuel: Boatfuel,
            BoatWeight_forbyfor_BoatBall: Boatforbyfor_BoatBall,
            BoatWeight_GCM: boatforbyfor_GCM,
            id: id
          }
        }, { merge: true })
  };

  const onSubmitCaravan = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    e.preventDefault();
      const id = Math.random() * 6 * 1000
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          Caravan: {
            Caravan_Empty: caravanempty,
            Caravan_ATM: caravanATM,
            Caravan_Payload: caravanpayload,
            Caravan_Water: caravanwater,
            Caravan_Greywater: caravangrey,
            Caravan_Total: caravan_Total,
            Caravan_forbyfor_Total: caravantotal,
            caravan_forbyfor_caravanBall: caravanforbyfor_caravanBall,
            caravan_GCM: caravanforbyfor_GCM,
            id: id
          }
        }, { merge: true })
  };
  
  const onSubmitCamperWeights = e => {
      const id = Math.random() * 6 * 1000
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          Camper: {
            Camper_Empty: camperempty,
            Camper_ATM: camperATM,
            Camper_Payload: camperpayload,
            Camper_Water: camperwater,
            Camper_Total: camper_Total,
            Camper_CamperBall: camperforbyfor_CamperBall,
            Camper_forbyfor_Total: forbyfortotal,
            CamperplusforbyforTotal: campertotal,
            camperforbyfor_GCM: camperforbyfor_GCM,
            id: id,
          }
        }, { merge: true })
  };

  const onSubmitWeights = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    if (e != undefined) {
      e.preventDefault();
    }
      const id = Math.random() * 6 * 1000
      firebase
        .firestore()
        .collection("user")
        .doc(user1)
        .update({
          TorquePower: {
            Torque: Number(torque),
            PowerKW: Number(power),
            PowerHP: Number(powerhp),
          },
          FourbyFourPlusCaravan: {
            FourbyFour_Empty: fourbyfourempty,
            FourbyFour_Payload: fourbyfourpayload,
            FourbyFour_Water: fourbyfourwater,
            FourbyFour_GVM: fourbyfourGVM,
            FourbyFour_FUEL: fourbyfourFuel,
            FourbyFour_TOTAL: forbyfortotal,
            id: id
          }
        }, { merge: true }).then(() => {
          alert('Weights successfully updated.')
        })
  };

  const [date, setdata] = useState([])
  
  const handleChange = (e, i) => {
    const clonedData = [...date];
    clonedData[i][e.target.name] = e.target.value;
    setdata(clonedData);
  }
  const [edit, setedit] = useState(false)

  

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);
  const handleChangeT = (event, newValue) => {
    setValue(newValue);
  };

  /* TRAVELLER AUTO SAVE
  let intervalID;
  useEffect(() => {
      intervalID = setInterval(() => {
        const formSubmitButton = document.querySelectorAll(".btn2, .btn3, .btn4, .btn5, .btn6");
        if (formSubmitButton != null) {
          formSubmitButton.forEach(el=>el.click());
        }
      }, 2000);
    return () => clearInterval(intervalID);
  }); */

  /* WEIGHTS AUTO SAVE
  let intervalID2;
  useEffect(() => {
    if (value == 2) {
      intervalID2 = setInterval(() => {
        const formSubmitButton = document.querySelectorAll(".btn2, .btn3, .btn4, .btn5, .btn6");
        if (formSubmitButton != null) {
          formSubmitButton.forEach(el=>el.click())
        }
      }, 2000);
    }
    return () => clearInterval(intervalID2);
  }, [value == 2]);
  */   

  
  const userO = firebase.auth().currentUser;

    
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
  }));

  const classes = useStyles();

  const user2 = user.email;

  const lastnumberofdata = date?.length - 1
  useEffect(() => {
    if (fourbyfourempty == "" && fourbyfourGVM == "" && fourbyfourFuel == "" && fourbyfourwater == ""
    && camperempty == "" && camperATM == "" && camperwater == "" && camperforbyfor_CamperBall == "" && camperforbyfor_GCM == ""
    && caravanempty == '' && caravanATM == "" && caravanwater == '' && caravanforbyfor_caravanBall == '' && caravanforbyfor_GCM == ""
    && Boatempty == '' && BoatATM == "" && Boatfuel == '' && Boatwater == "" && Boatforbyfor_BoatBall == "" && boatforbyfor_GCM == ""
    && adventureMotorbile_Empty == '' && adventureMotorbile_GVM == "" && adventureMotorbile_Fuel == '' && adventureMotorbile_Water == '') {
              listItemUser?.map(item => {
                setFourbyFourEmpty(item?.FourbyFourPlusCaravan?.FourbyFour_Empty)
                // setFourbyFourPayload(item.FourbyFourPlusCaravan.FourbyFour_Payload)
                setFourbyFourWater(item?.FourbyFourPlusCaravan?.FourbyFour_Water)
                setFourbyFourGVM(item?.FourbyFourPlusCaravan?.FourbyFour_GVM)
                setFourbyFourFuel(item?.FourbyFourPlusCaravan?.FourbyFour_FUEL)
                  setcamperEmpty(item?.Camper?.Camper_Empty)
                  setcamperATM(item?.Camper?.Camper_ATM)
                  // setcamperPayload(item?.Camper?.Camper_Payload)
                  setcamperWater(item?.Camper?.Camper_Water)
                  setcamperforbyfor_GCM(item?.Camper?.camperforbyfor_GCM)
                  setcamperforbyfor_CamperBall(item?.Camper?.Camper_CamperBall)
                  setcaravanforbyfor_BoatBall(item?.Caravan?.caravan_forbyfor_caravanBall)
                  setcaravanforbyfor_GCM(item?.Caravan?.caravan_GCM)
                  setcaravanEmpty(item?.Caravan?.Caravan_Empty)
                  setcaravanATM(item?.Caravan?.Caravan_ATM)
                  // setcaravanPayload(item?.Caravan?.Caravan_Payload)
                  setcaravanWater(item?.Caravan?.Caravan_Water)
                  setcaravanGrey(item?.Caravan?.Caravan_Greywater)
                  setBoatforbyfor_BoatBall(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall)
                setboatforbyfor_GCM(item?.BoatWeight?.BoatWeight_GCM)
                setBoatEmpty(item?.BoatWeight?.BoatWeight_Empty)
                setBoatATM(item?.BoatWeight?.BoatWeight_ATM)
                // setBoatPayload(item?.BoatWeight?.BoatWeight_Payload)
                setBoatWater(item?.BoatWeight?.BoatWeight_Water)
                setBoatFuel(item?.BoatWeight?.BoatWeight_Fuel)
                setadventureMotorbile_Empty(item?.AdventureMotorBike?.AdventureMotorBike_Empty)
                setadventureMotorbile_GVM(item?.AdventureMotorBike?.AdventureMotorBike_GVM)
                // setadventureMotorbile_Payload(item?.AdventureMotorBike?.AdventureMotorBike_Payload)
                setBoatsetadventureMotorbile_Fuel(item?.AdventureMotorBike?.AdventureMotorBike_Fuel)
                setBoatsetadventureMotorbile_Water(item?.AdventureMotorBike?.AdventureMotorBike_Water)
                setTorque(item?.TorquePower?.Torque)
                setPower(item?.TorquePower?.PowerKW)
                setPowerHP(item?.TorquePower?.PowerHP)
                }
              )
            }
          })

  const unSaved = useRef(false);

  // useEffect(() => {
  //   if (fourbyfourempty,fourbyfourGVM) {
  //     unSaved.current = true;
  //   } else {
  //     unSaved.current = false;
  //   }
  // }, [fourbyfourempty,fourbyfourGVM]);

  const beforeunload = (e) => {
    // You can add logic for confirmation here.

    if (unSaved.current) {
      e.preventDefault();
      e.returnValue = true;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, []);

  const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

  return (
    <div className="additem profilefields">
      <div style={{color:'red',textTransform:'uppercase'}}>{unSaved.current && <span>You have unsaved data</span>}</div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon>4x4</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <form style={{ marginTop: '0' }}>
          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
          <label className="travellerslabel">WEIGHTS</label>
            <div className="garage-wrapper">
              <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The weight of your empty 4x4 including permanently fitted accessories like bull bars and roof racks. Best calculated on a weighbridge." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={fourbyfourempty} onChange={(e) => (setFourbyFourEmpty(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="FourbyFour_Empty" variant="outlined" />
              
              <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The maximum legal limit of your 4x4 plus all loads. This is the manufacturer limit unless your vehicle has a GVM upgrade." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.DistancePref == undefined || item.DistancePref ? "GVM  " : "GVWR "}
                    {item.WeightPref == undefined || item.WeightPref ? "(kg)" : "(lb)"}
                  </>
                    )
              })} value={fourbyfourGVM} onChange={(e) => (setFourbyFourGVM(e.target.value), setDirty(true))} name="FourbyFour_GVM" variant="outlined" />
              
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={fourbyfourpayload} name="FourbyFour_Payload"  variant="outlined" />
              
              <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Fuel weight = volume x per litre weight. 1L diesel = 0.84kg, 1 gallon diesel = 7lbs, 1L unleaded = 0.74kg and 1 gallon unleaded = 6lbs." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={fourbyfourFuel} onChange={(e) => (setFourbyFourFuel(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="FourbyFour_FUEL" variant="outlined" />
              
              <TextField enterTouchDelay={0} type="number" InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={fourbyfourwater} onChange={(e) => (setFourbyFourWater(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="FourbyFour_Water"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "TOTAL (kg)" : "TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotal} name="FourbyFour_TOTAL"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={fourbyfourremaining} name="FourbyFour_Remaining"  variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"}  />
              </div>
              </div>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField type="number" label="TORQUE (Nm)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={torque} onChange={(e) => (setTorque(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" variant="outlined" value={fourbyfourempty && torque ? '1 : ' +  (fourbyfourempty / torque).toFixed(0) : 0} />
              <TextField type="number" label="POWER (kW)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={power} onChange={(e) => (setPower(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" variant="outlined" value={fourbyfourempty && power ? '1 : ' +  (fourbyfourempty / power).toFixed(0) : 0} />
              <TextField type="number" label="POWER (HP)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={powerhp} onChange={(e) => (setPowerHP(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" variant="outlined" value={fourbyfourempty && powerhp ? '1 : ' +  (fourbyfourempty / powerhp).toFixed(0) : 0} />
              </div>
              </div>
              <br /><em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
            <Button
              disableElevation
              variant="outlined"
              style={{display: 'none'}}
              onClick={(e) => onSubmitWeights(e)}
              color="default"
              size="large"
              type="submit"
              className="btn2"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Weights
            </Button>
          </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faTrailer} size="lg"></FontAwesomeIcon>CAMPER</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <form style={{ marginTop: '0' }}>
            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
            <label className="travellerslabel">WEIGHTS</label>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTrailer} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={camperempty} onChange={(e) => (setcamperEmpty(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_Empty"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "ATM (kg)" : "ATM (lb)"}
                  </>
                    )
              })} value={camperATM} onChange={(e) => (setcamperATM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_ATM"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={camperpayload} name="Camper_Payload"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={camperwater} onChange={(e) => (setcamperWater(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_Water"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "TOTAL (kg)" : "TOTAL (lb)"}
                  </>
                    )
              })} value={camper_Total} name="Total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={camperremaining} name="Camper_Remaining"  variant="outlined" className={(camperremaining < 0 ) ? "negative" : "positive"} />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={camperforbyfor_CamperBall} onChange={(e) => (setcamperforbyfor_CamperBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_CamperBall"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "CAMPER - BALL WEIGHT TOTAL (kg)" : "CAMPER - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={campernoballweight} name="Camper_Remaining"  variant="outlined" />
              </div>
              <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
              <div className="garage-wrapper">
                <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The weight of your empty 4x4 including permanently fitted accessories like bull bars and roof racks. Best calculated on a weighbridge." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={fourbyfourempty} disabled name="FourbyFour_Empty"  variant="outlined" />
                <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The maximum legal limit of your 4x4 plus all loads. This is the manufacturer limit unless your vehicle has a GVM upgrade." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.DistancePref == undefined || item.DistancePref ? "GVM  " : "GVWR "}
                    {item.WeightPref == undefined || item.WeightPref ? "(kg)" : "(lb)"}
                  </>
                    )
              })} value={fourbyfourGVM} disabled name="FourbyFour_GVM"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={fourbyfourpayload} name="FourbyFour_Payload"  variant="outlined" />
                <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Fuel weight = volume x per litre weight. 1L diesel = 0.84kg, 1 gallon diesel = 7lbs, 1L unleaded = 0.74kg and 1 gallon unleaded = 6lbs." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={fourbyfourFuel} disabled name="FourbyFour_FUEL"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={fourbyfourwater} disabled name="FourbyFour_Water"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={camperforbyfor_CamperBall} onChange={(e) => (setcamperforbyfor_CamperBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_CamperBall"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotalplusball} name="FourbyFour_TOTAL"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={fourbyfourremaining - camperforbyfor_CamperBall} name="FourbyFour_Remaining"  variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
              </div>
              <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><span style={{marginRight:'10px'}}>+</span><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faTrailer} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
              <div className="garage-wrapper">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 GCM (kg)" : "4x4 GCM (lb)"}
                  </>
                    )
              })} value={camperforbyfor_GCM} onChange={(e) => (setcamperforbyfor_GCM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Camper_GCM"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={camper4x4_Total} name="4x4Total"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "CAMPER - BALL WEIGHT TOTAL (kg)" : "CAMPER - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={camperminusball_Total} name="camperTotal"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + CAMPER TOTAL (kg)" : "4x4 + CAMPER TOTAL (lb)"}
                  </>
                    )
              })} value={campertotal} name="Total" variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} id="calculated" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={camperremaining_Total} name="Remaining"  variant="outlined" className={(camperremaining_Total < 0 ) ? "negative" : "positive"} />
              </div>
              </div>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" variant="outlined" value={campertotal && torque ? '1 : ' +  (campertotal / torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={power} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" variant="outlined" value={campertotal && power ? '1 : ' +  (campertotal / power).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={powerhp} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" variant="outlined" value={campertotal && powerhp ? '1 : ' +  (campertotal / powerhp).toFixed(0) : 0} />
              </div>
              </div>
              <br /><em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
            <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              onClick={(e) => onSubmitCamperWeights(e)}
              style={{display: 'none'}}
              className="btn3"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Camper Weights
                    </Button>
          </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faCaravanSimple} size="lg"></FontAwesomeIcon>CARAVAN</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <form style={{ marginTop: '0' }}>
            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
            <label className="travellerslabel">WEIGHTS</label>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faCaravanSimple} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={caravanempty} onChange={(e) => (setcaravanEmpty(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Empty"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "ATM (kg)" : "ATM (lb)"}
                  </>
                    )
              })} value={caravanATM} onChange={(e) => (setcaravanATM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_ATM"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" disabled id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={caravanpayload} name="Caravan_Payload"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={caravanwater} onChange={(e) => (setcaravanWater(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Water"  variant="outlined" />
              { /* <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label="GREYWATER (kg)" value={caravangrey} onChange={(e) => e.target.value === "" && caravangrey !== 0 ? setcaravanGrey(0) : setcaravanGrey(e.target.value)} name="Caravan_Greywater"  variant="outlined" />&nbsp; */ }
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" disabled id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "TOTAL (kg)" : "TOTAL (lb)"}
                  </>
                    )
              })} value={caravan_Total} name="Caravan_total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={caravanremaining} name="Camper_Remaining"  variant="outlined" className={(caravanremaining < 0 ) ? "negative" : "positive"} />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={caravanforbyfor_caravanBall} onChange={(e) => (setcaravanforbyfor_BoatBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Ball"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "CARAVAN - BALL WEIGHT TOTAL (kg)" : "CARAVAN - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={caravannoballweight} name="Caravan_Remaining"  variant="outlined" />
              </div>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
              <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The weight of your empty 4x4 including permanently fitted accessories like bull bars and roof racks. Best calculated on a weighbridge." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={fourbyfourempty} disabled name="FourbyFour_Empty"  variant="outlined" />
              <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The maximum legal limit of your 4x4 plus all loads. This is the manufacturer limit unless your vehicle has a GVM upgrade." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.DistancePref == undefined || item.DistancePref ? "GVM  " : "GVWR "}
                    {item.WeightPref == undefined || item.WeightPref ? "(kg)" : "(lb)"}
                  </>
                    )
              })} value={fourbyfourGVM} disabled  name="FourbyFour_GVM"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={fourbyfourpayload} name="FourbyFour_Payload"  variant="outlined" />
                <TextField type="number" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Fuel weight = volume x per litre weight. 1L diesel = 0.84kg, 1 gallon diesel = 7lbs, 1L unleaded = 0.74kg and 1 gallon unleaded = 6lbs." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={fourbyfourFuel} disabled name="FourbyFour_FUEL"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={fourbyfourwater} disabled name="FourbyFour_Water"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={caravanforbyfor_caravanBall} onChange={(e) => (setcaravanforbyfor_BoatBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Ball"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={fourbyfourremaining} name="FourbyFour_Remaining"  variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
              </div>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><span style={{marginRight:'10px'}}>+</span><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faCaravanSimple} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 GCM (kg)" : "4x4 GCM (lb)"}
                  </>
                    )
              })} value={caravanforbyfor_GCM} onChange={(e) => (setcaravanforbyfor_GCM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Empty"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotalcaraplusball} name="Caravan_4x4 total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "CARAVAN - BALL WEIGHT TOTAL (kg)" : "CARAVAN - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={caravanminusball_Total} name="Caravan_Total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + CARAVAN TOTAL (kg)" : "4x4 + CARAVAN TOTAL (lb)"}
                  </>
                    )
              })} value={caravantotal} name="Total" variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} id="calculated" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={caravanremaining_Total} name="FourbyFour_Remaining"  variant="outlined" className={(caravanremaining_Total < 0 ) ? "negative" : "positive"} />
              </div>
              </div>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" variant="outlined" value={caravantotal && torque ? '1 : ' +  (caravantotal / torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={power} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" variant="outlined" value={caravantotal && power ? '1 : ' +  (caravantotal / power).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={powerhp} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" variant="outlined" value={caravantotal && powerhp ? '1 : ' +  (caravantotal / powerhp).toFixed(0) : 0} />
              </div>
              </div>
              <br /><em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
            <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              onClick={(e) => onSubmitCaravan(e)}
              style={{display: 'none'}}
              className="btn4"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Caravan Weights
                    </Button>
          </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faShip} size="lg"></FontAwesomeIcon>BOAT</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <form style={{ marginTop: '0' }}>
            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
            <label className="travellerslabel">WEIGHTS</label>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faShip} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={Boatempty} onChange={(e) => (setBoatEmpty(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_Empty"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "ATM (kg)" : "ATM (lb)"}
                  </>
                    )
              })} value={BoatATM} onChange={(e) => (setBoatATM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_ATM"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={Boatpayload} name="BoatWeight_Payload"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={Boatfuel} onChange={(e) => (setBoatFuel(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_Fuel"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={Boatwater} onChange={(e) => (setBoatWater(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_Water"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "TOTAL (kg)" : "TOTAL (lb)"}
                  </>
                    )
              })} value={Boat_Total} name="Total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={boatremaining} name="Camper_Remaining"  variant="outlined" className={(boatremaining < 0 ) ? "negative" : "positive"} />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={Boatforbyfor_BoatBall} onChange={(e) => (setBoatforbyfor_BoatBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_BoatBall"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BOAT - BALL WEIGHT TOTAL (kg)" : "BOAT - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={boatnoballweight} name="Camper_Remaining"  variant="outlined" />
              </div>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
              <TextField InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The weight of your empty 4x4 including permanently fitted accessories like bull bars and roof racks. Best calculated on a weighbridge." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={fourbyfourempty} disabled name="FourbyFour_Empty"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="The maximum legal limit of your 4x4 plus all loads. This is the manufacturer limit unless your vehicle has a GVM upgrade." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.DistancePref == undefined || item.DistancePref ? "GVM  " : "GVWR "}
                    {item.WeightPref == undefined || item.WeightPref ? "(kg)" : "(lb)"}
                  </>
                    )
              })} value={fourbyfourGVM} disabled name="FourbyFour_GVM"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={fourbyfourpayload} name="FourbyFour_Payload"  variant="outlined" />
                <TextField InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric', endAdornment: <InputAdornment position="end"><Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Fuel weight = volume x per litre weight. 1L diesel = 0.84kg, 1 gallon diesel = 7lbs, 1L unleaded = 0.74kg and 1 gallon unleaded = 6lbs." placement="top-start" arrow>
              <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
            </Tooltip></InputAdornment>,
              }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={fourbyfourFuel} onChange={(e) => (setFourbyFourFuel(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="FourbyFour_FUEL"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={fourbyfourwater} disabled name="FourbyFour_Water"  variant="outlined" />
              <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BALL WEIGHT (kg)" : "BALL WEIGHT (lb)"}
                  </>
                    )
              })} value={Boatforbyfor_BoatBall} onChange={(e) => (setcaravanforbyfor_BoatBall(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="Caravan_Ball"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={fourbyfourremaining} name="FourbyFour_Remaining"  variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
              </div>
            <h2 className="weightsh2"><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px', marginBottom:'3px'}} icon={faTruckMonster} size="lg"></FontAwesomeIcon><span style={{marginRight:'10px'}}>+</span><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faShip} size="lg"></FontAwesomeIcon><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faWeightScale} size="lg"></FontAwesomeIcon></h2>
            <div className="garage-wrapper">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 GCM (kg)" : "4x4 GCM (lb)"}
                  </>
                    )
              })} value={boatforbyfor_GCM} onChange={(e) => (setboatforbyfor_GCM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="BoatWeight_Empty"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BALL WEIGHT TOTAL (kg)" : "4x4 + BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={forbyfortotalcaraplusball} name="BoatWeight_4x4 Total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "BOAT - BALL WEIGHT TOTAL (kg)" : "BOAT - BALL WEIGHT TOTAL (lb)"}
                  </>
                    )
              })} value={boatminusball_Total} name="BoatWeight_4x4 Total"  variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "4x4 + BOAT TOTAL (kg)" : "4x4 + BOAT TOTAL (lb)"}
                  </>
                    )
              })} value={boattotal} name="BoatWeightttal" variant="outlined" />
              <TextField disabled InputLabelProps={{ shrink: true, }} id="calculated" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={boatremaining_Total} name="FourbyFour_Remaining"  variant="outlined" className={(boatremaining_Total < 0 ) ? "negative" : "positive"} />
              </div></div>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" variant="outlined" value={boattotal && torque ? '1 : ' +  (boattotal / torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={power} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" variant="outlined" value={boattotal && power ? '1 : ' +  (boattotal / power).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={powerhp} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" variant="outlined" value={boattotal && powerhp ? '1 : ' +  (boattotal / powerhp).toFixed(0) : 0} />
              </div>
              </div>
              <br /><em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
            <Button
              disableElevation
              variant="outlined"
              onClick={(e) => onSubmitBoatWeight(e)}
              style={{display: 'none'}}
              color="default"
              size="large"
              type="submit"
              className="btn5"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Boat Weights
                    </Button>
          </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><FontAwesomeIcon className="fa-fw" style={{display:'inline-block', verticalAlign:'middle', marginRight:'10px'}} icon={faMotorcycle} size="lg"></FontAwesomeIcon>ADVENTURE MOTORBIKE</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <form style={{ marginTop: '0' }}>
          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
            <label className="travellerslabel">WEIGHTS</label>
          <div className="garage-wrapper">
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "EMPTY (kg)" : "EMPTY (lb)"}
                  </>
                    )
              })} value={adventureMotorbile_Empty} onChange={(e) => (setadventureMotorbile_Empty(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="AdventureMotorBike_Empty"  variant="outlined" />
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.DistancePref == undefined || item.DistancePref ? "GVM  " : "GVWR "}
                    {item.WeightPref == undefined || item.WeightPref ? "(kg)" : "(lb)"}
                  </>
                    )
              })} value={adventureMotorbile_GVM} onChange={(e) => (setadventureMotorbile_GVM(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="AdventureMotorBike_GVM"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "PAYLOAD (kg)" : "PAYLOAD (lb)"}
                  </>
                    )
              })} value={adventureMotorbile_Payload} name="AdventureMotorBike_Payload"  variant="outlined" />
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "FUEL (kg)" : "FUEL (lb)"}
                  </>
                    )
              })} value={adventureMotorbile_Fuel} onChange={(e) => (setBoatsetadventureMotorbile_Fuel(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="AdventureMotorBike_Fuel"  variant="outlined" />
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'numeric' }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "WATER (kg)" : "WATER (lb)"}
                  </>
                    )
              })} value={adventureMotorbile_Water} onChange={(e) => (setBoatsetadventureMotorbile_Water(e.target.value), setDirty(true), bottomnavtarget.classList.add('cf'))} name="AdventureMotorBike_Water"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "TOTAL (kg)" : "TOTAL (lb)"}
                  </>
                    )
              })} value={adventure_Total} name="total"  variant="outlined" />
            <TextField disabled InputLabelProps={{ shrink: true, }} type="number" id="standard-basic" label={listItemUser?.map(item => {
                return (
                  <>
                    {item.WeightPref == undefined || item.WeightPref ? "REMAINING CAPACITY (kg)" : "REMAINING CAPACITY (lb)"}
                  </>
                    )
              })} value={advmotoremaining} name="FourbyFour_Remaining"  variant="outlined" className={(advmotoremaining < 0 ) ? "negative" : "positive"} />
              </div></div>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" variant="outlined" value={adventure_Total && torque ? '1 : ' +  (adventure_Total / torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={power} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" variant="outlined" value={adventure_Total && power ? '1 : ' +  (adventure_Total / power).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={powerhp} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" variant="outlined" value={adventure_Total && powerhp ? '1 : ' +  (adventure_Total / powerhp).toFixed(0) : 0} />
              </div>
              </div>
              <br /><em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
            <Button
              disableElevation
              variant="outlined"
              onClick={(e) => onSubmitAdventureMotorbike(e)}
              style={{display: 'none'}}
              color="default"
              size="large"
              type="submit"
              className="btn6"
              startIcon={<SaveAltTwoToneIcon />}
            >
              Save Motorbike Weights
                    </Button>
          </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br/><br/>
      <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              onClick={(e) => { onSubmitCamperWeights(e); onSubmitCaravan(e); onSubmitBoatWeight(e); onSubmitAdventureMotorbike(e); setDirty(false); bottomnavtarget.classList.remove('cf') }}
              className="btn stickybtn mobilesplitbtn blackbtn dirtysubmit"
            >
              Save All Weights
             </Button>   
    </div>
  );
};
export default AddItemFormGarage;